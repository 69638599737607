import { action, observable, set } from 'mobx';
import moment, { asTime, dateWithoutTime } from '../utils/moment';
import TripRoute from './TripRoute';
import TaxExemptTripExpense from './TaxExemptTripExpense';
// import WorkTask from '../employer-work-orders/work-tasks/WorkTask';
import WorkTaskEntry from './WorkTaskEntry';
// import UserProductCollection from '../models/product/UserProductCollection';

const getFloat = (value) => {
  let newValue;
  if (typeof value === 'string') {
    newValue = Math.round(parseFloat(value || '0.0') * 100) / 100;
  }

  if (newValue === '' || newValue == null || Number.isNaN(newValue) || newValue === 0) {
    newValue = '';
  }

  return newValue;
};

const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

// Names of attributes that should be saved as separate tax exempt trip expenses
// This is to keep the acceptance of trip-related things and work-related things separate
const taxExemptTripExpenseNames = ['allowance'];

export default class TimelogEntry {
  @observable allowance;

  @observable createdAt;

  @observable date;

  @observable description;

  @observable driveTime;

  @observable driveTime50;

  @observable driveTime100;

  @observable emergencyWork;

  @observable eveningShift;

  @observable from;

  @observable id;

  @observable kms;

  @observable kmsKit;

  @observable kmsPerson;

  @observable kmsTrailer;

  @observable kmsService;

  @observable kmsHeavyLoad;

  @observable nightShift;

  @observable status;

  @observable sundayWork;

  @observable time100;

  @observable time50;

  @observable timeNormal;

  @observable to;

  @observable travelTime;

  @observable updatedAt;

  @observable userId;

  @observable weeklyRest;

  @observable workOrderId;

  @observable conditionCompensation;

  @observable conditionCompensationTwo;

  @observable conditionCompensationThree;

  @observable conditionCompensationFour;

  // @observable workNumber;

  // @observable workTarget;

  @observable workTask;

  @observable tripRoutes = [];

  @observable miningCompensation;

  @observable midweekHolidayCompensation;

  @observable workTaskEntries = [];

  // A field that combines timeNormal, time50 and time100 for billing reports
  @observable timeTotal;

  @observable latestEmployeeVersion = {};

  @observable employerUpdateComment = {};

  @observable taxExemptTripExpenses = [];

  @observable workOrderTripEmployerComment;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(object) {
    const o = { ...object };
    // At this stage we mostly skip the "proper" UI management of taxExemptTripExpenses as their own objects inside this TimelogEntry
    // We turn them into their own objects out of the updated values on save
    // Then they'll be returned from the DB as "normal" values of the TimelogEntry, e.g. kms, due to model custom accessors
    // In other words, the taxExemptTripExpenses are barely used here
    Object.keys(o).forEach((key) => {
      if (taxExemptTripExpenseNames.includes(key)) {
        // The value is a tax exempt trip expense, move into its own object for saving into its own table
        const foundExpense = o.taxExemptTripExpenses.find((expense) => expense.name === key);
        if (foundExpense) {
          // Update the old expense
          foundExpense.value = o[key];
          foundExpense.status = 'draft';
        } else {
          // Create new
          const newExpense = new TaxExemptTripExpense({ name: key, value: o[key], status: 'draft' });
          o.taxExemptTripExpenses.push(newExpense);
        }
        o[key] = null;
      }
    });

    return {
      allowance: o.allowance === 'none' ? null : o.allowance,
      date: o.date,
      description: o.description,
      drive_time: o.driveTime,
      drive_time_50: o.driveTime50,
      drive_time_100: o.driveTime100,
      emergency_work: o.emergencyWork,
      evening_shift: o.eveningShift,
      from: o.from,
      id: o.id,
      // kms: o.kms,
      kms_kit: o.kmsKit,
      kms_person: o.kmsPerson,
      kms_trailer: o.kmsTrailer,
      kms_service: o.kmsService,
      kms_heavy_load: o.kmsHeavyLoad,
      night_shift: o.nightShift,
      // status: (o.status == null) ? 'draft' : o.status,
      sunday_work: o.sundayWork,
      time_100: o.time100,
      time_50: o.time50,
      time_normal: o.timeNormal,
      to: o.to,
      travel_time: o.travelTime,
      // userId should be either null (replaced by current_api_user.id) or an employee ID set by the employer's editing mode
      user_id: o.userId,
      weekly_rest: o.weeklyRest,
      work_order_id: o.workOrderId,
      condition_compensation: o.conditionCompensation,
      condition_compensation_two: o.conditionCompensationTwo,
      condition_compensation_three: o.conditionCompensationThree,
      condition_compensation_four: o.conditionCompensationFour,
      // work_number: o.workNumber,
      // work_target: o.workTarget,
      work_task_id: o.workTask ? o.workTask.id : null,
      mining_compensation: o.miningCompensation,
      midweek_holiday_compensation: o.midweekHolidayCompensation,
      // TODO: TripRoute.toJson() here + nested attributes
      trip_routes_attributes: o.tripRoutes?.map((tripRoute) => TripRoute.toJson(tripRoute)),
      work_task_entries_attributes: o.workTaskEntries?.map((workTaskEntry) => WorkTaskEntry.toJson(workTaskEntry)),
      employer_update_comment_attributes: { comment: o.employerUpdateComment?.comment },
      tax_exempt_trip_expenses_attributes: o.taxExemptTripExpenses?.map((tripExpense) => TaxExemptTripExpense.toJson(tripExpense)),
    };
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    if (attr === 'allowance') {
      const taxExemptTripExpense = this.taxExemptTripExpenses.find((expense) => expense.name === 'allowance');
      if (taxExemptTripExpense) {
        this.allowance = value;
        taxExemptTripExpense.status = 'draft';
        taxExemptTripExpense.value = value;
      } else {
        this.allowance = value;
        this.taxExemptTripExpenses.push(new TaxExemptTripExpense({ name: 'allowance', value, status: 'draft' }));
      }
    } else {
      this[attr] = value;
    }
  }

  @action changeFloatAttr(attr, value) {
    set(this, attr, getFloat(value));
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  @action updatePropertiesFromJson(o) {
    // if (o.date === '2023-04-05') {
    //   // eslint-disable-next-line camelcase
    //   console.log('latest employee version time_50: ', o.latest_employee_version.time_normal);
    //   // eslint-disable-next-line camelcase

    //   console.log('current live work hour time_50: ', o.time_normal);
    // }

    const updatedAt = moment(o.updated_at);

    // const json = { ...o };

    // At this stage we mostly skip the "proper" UI management of taxExemptTripExpenses as their own objects inside this TimelogEntry
    // We turn them into their own objects out of the updated values on save
    // Then they'll be returned from the DB as "normal" values of the TimelogEntry, e.g. kms, due to model custom accessors
    // In other words, the taxExemptTripExpenses are barely used here
    // Object.keys(o).forEach((key) => {
    //   if (taxExemptTripExpenseNames.includes(key)) {
    //     // The value is a tax exempt trip expense, move into its own object for saving into its own table
    //     const foundExpense = o.tax_exempt_trip_expenses.find((expense) => expense.name === key);
    //     if (foundExpense) {
    //       // Update the old expense
    //       foundExpense.value = o[key];
    //     } else {
    //       // Create new
    //       const newExpense = new TaxExemptTripExpense({ name: key, value: o[key] });
    //       o.taxExemptTripExpenses.push(newExpense);
    //     }
    //     // eslint-disable-next-line no-param-reassign
    //     o[key] = null;
    //   }
    // });

    // update content only if received more up to date content – this may happen e.g. when multiple updates are delayed due to slow network
    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return null;
    }

    const values = {
      updatedAt,
      createdAt: getDate(o.created_at),
      allowance: o.allowance,
      date: getDate(o.date),
      description: o.description,
      driveTime: getFloat(o.drive_time),
      driveTime50: getFloat(o.drive_time_50),
      driveTime100: getFloat(o.drive_time_100),
      emergencyWork: o.emergency_work,
      eveningShift: getFloat(o.evening_shift),
      from: asTime(o.from),
      id: o.id,
      kms: o.kms,
      kmsPerson: o.kms_person,
      kmsTrailer: o.kms_trailer,
      kmsKit: o.kms_kit,
      kmsService: o.kms_service,
      kmsHeavyLoad: o.kms_heavy_load,
      nightShift: getFloat(o.night_shift),
      status: o.status == null ? 'draft' : o.status,
      sundayWork: getFloat(o.sunday_work),
      time100: getFloat(o.time_100),
      time50: getFloat(o.time_50),
      timeNormal: getFloat(o.time_normal),
      to: asTime(o.to),
      travelTime: getFloat(o.travel_time),
      userId: o.user_id,
      weeklyRest: getFloat(o.weekly_rest),
      workOrderId: o.work_order_id,
      conditionCompensation: getFloat(o.condition_compensation),
      conditionCompensationTwo: getFloat(o.condition_compensation_two),
      conditionCompensationThree: getFloat(o.condition_compensation_three),
      conditionCompensationFour: getFloat(o.condition_compensation_four),
      // workNumber: o.work_number,
      // workTarget: o.work_target,
      // workTask: o.work_task ? WorkTask.fromJsonProperties(o.work_task) : null,
      employerComment: o.employer_comment,
      // tripRoutes: o.trip_routes?.map((item) => TripRoute.fromJsonProperties(item)),
      miningCompensation: getFloat(o.mining_compensation),
      midweekHolidayCompensation: getFloat(o.midweek_holiday_compensation),
      // userProductCollection: new UserProductCollection(o.user_product_collection),
      workTaskEntries: o.work_task_entries ? o.work_task_entries.map((workTaskEntry) => WorkTaskEntry.fromJsonProperties(workTaskEntry)) : [],
      taxExemptTripExpenses: o.tax_exempt_trip_expenses ? o.tax_exempt_trip_expenses.map((taxExemptTripExpense) => TaxExemptTripExpense.fromJsonProperties(taxExemptTripExpense)) : [],
      timeTotal: ((getFloat(o.time_normal) || 0) + (getFloat(o.time_50) || 0) + (getFloat(o.time_100) || 0)) || null,
      latestEmployeeVersion: o.latest_employee_version ? new TimelogEntry().updatePropertiesFromJson(o.latest_employee_version) : new TimelogEntry(),
      employerUpdateComment: { comment: o.employer_update_comment?.comment, author: o.employer_update_comment?.author },
      workOrderTripEmployerComment: o.work_order_trip_employer_comment,
    };

    let tripRoutes = [];
    if (o.trip_routes != null && o.trip_routes.length > 0) {
      tripRoutes = o.trip_routes.map((item) => TripRoute.fromJsonProperties(item));
      // Calculate the kms total from trip_routes for the UI
      // values.kms = o.trip_routes.reduce((accumulator, tripRoute) => accumulator + tripRoute.kms, 0);
    } else {
      tripRoutes.push(new TripRoute({}));
    }
    values.tripRoutes = tripRoutes;

    if (o.tax_exempt_trip_expenses) {
      o.tax_exempt_trip_expenses.forEach((taxExemptTripExpense) => {
        if (taxExemptTripExpenseNames.includes(taxExemptTripExpense.name)) {
          values[taxExemptTripExpense.name] = taxExemptTripExpense.value;
        }
      });
    }

    // if (o.tax_exempt_trip_expenses.length > 0) {
    //   console.log('Values: ', values);
    // }

    // We want to create a placeholder row for instantly showing the changed values vs. old values in the UI for employers
    if (!o.latest_employee_version) {
      const latestEmployeeVersionPlaceholderValues = { ...values };
      delete latestEmployeeVersionPlaceholderValues.latestEmployeeVersion;
      values.latestEmployeeVersion.updateProperties(latestEmployeeVersionPlaceholderValues);
    }

    return this.updateProperties(values);
  }

  hasAcceptableValues() {
    const excludedKeys = ['id', 'createdAt', 'updatedAt', 'date', 'workOrderId', 'userId', 'taxExemptTripExpenses', 'tripRoutes', 'latestEmployeeVersion', 'employerUpdateComment', 'status', 'allowance', 'kms'];
    const relevantKeysWithValues = Object.keys(this).filter((key) => this[key] && (!Array.isArray(this[key]) || this[key].length > 0) && !excludedKeys.includes(key));
    return relevantKeysWithValues.length > 0;
  }

  isDraft() {
    // Does this make sense?
    return this.status === 'draft' || this.taxExemptTripExpenses.find((expense) => expense.status === 'draft' || this.tripRoutes.find((tripRoute) => tripRoute.status === 'draft'));
    // Would this be better?
    // return this.status === 'draft' || this.taxExemptTripExpenses.find((expense) => expense.status === 'draft' && expense.value) || this.tripRoutes.find((tripRoute) => tripRoute.status === 'draft');
  }

  copy() {
    const copy = new TimelogEntry(this);
    // Clean all IDs
    copy.id = null;
    copy.status = 'draft';
    copy.employerComment = null;
    copy.employerUpdateComment = null;
    copy.latestEmployeeVersion = null;
    copy.updatedAt = null;
    copy.createdAt = null;
    copy.tripRoutes = copy.tripRoutes.map((tripRoute) => new TripRoute({ ...tripRoute, id: null, workHourId: null }));
    copy.workTaskEntries = copy.workTaskEntries.map((workTaskEntry) => new WorkTaskEntry({ ...workTaskEntry, id: null, workHourId: null }));
    copy.taxExemptTripExpenses = copy.taxExemptTripExpenses.map((expense) => new TaxExemptTripExpense({ ...expense, id: null, workHourId: null }));
    return copy;
  }

  // tripRoutesFromJson(trips) {
  //   return trips.map(trip => TripRoute.fromJson(trip))
  // }

  // tripRoutesToJson(trips) {
  //   return trips.map(trip => TripRoute.toJson(trip))
  // }

  // @action updateTripExpenseCollection(attr, value) {
  //   if (!this.tripExpenseCollection) {
  //     this.tripExpenseCollection = new TripExpenseCollection();
  //   }
  //   this.tripExpenseCollection[attr] = value;
  // }

  // @action getTaxExemptTrip

  static fromJsonProperties(object) {
    const timelog = new TimelogEntry({});
    timelog.updatePropertiesFromJson(object);
    return timelog;
  }

  static fromJson(json) {
    return TimelogEntry.fromJsonProperties(JSON.parse(json));
  }
}

import React from 'react';
import { Typography } from '@rmwc/typography';
import { DirectUploadProvider } from 'react-activestorage-provider';
import {
  Card,
  CardPrimaryAction,
  CardMedia,
  CardMediaContent,
} from '@rmwc/card';
import { SimpleListItem, ListItem, CollapsibleList } from '@rmwc/list';
import { Select } from '@rmwc/select';
import { TextField } from '@rmwc/textfield';
// import { IconButton } from '@rmwc/icon-button';
import { Icon } from '@rmwc/icon';
import { Document, Page } from 'react-pdf';

import '@rmwc/avatar/avatar.css';
import '@material/card/dist/mdc.card.css';

import { handleProgress } from '../../models/user/user-ui-avatar';
import Validity from '../../models/Validity';
import noCard from '../../assets/images/pixel.png';
import '../validities/userValidities.css';

/* Copied from react-activestorage-provider's helpers */
const buildUrl = ({
  protocol,
  host,
  port,
  path,
}) => {
  if (!host) return path;

  const buildProtocol = protocol ? `${protocol.split(':')[0]}://` : '//';
  const builtPort = port ? `:${port}` : '';
  return buildProtocol + host + builtPort + path;
};


const addCardImage = (t, entityRef, entity, inputRef, onBlur, openCard) => {
  let fileName;

  return (
    <DirectUploadProvider
      directUploadsPath="/rails/active_storage/direct_uploads"

      multiple={false}

      onSuccess={(newData) => {
        if (Array.isArray(newData)) {
          const [value] = newData;
          // eslint-disable-next-line no-param-reassign
          entityRef[entity].imageURL = `/rails/active_storage/blobs/${value}/${fileName}`.split(' ').join('_');
          onBlur(null, entity, 'imageURL');
        }
      }}

      render={({ handleUpload, uploads, ready }) => {
        const { imageURL } = entityRef[entity];

        return (
          <>
            <input
              className="inputfile"
              disabled={!ready}
              id={`card-${entity}-uploader`}
              multiple={false}
              onChange={(e) => {
                if (e.currentTarget.files) {
                  [{ name: fileName }] = e.currentTarget.files;
                  handleUpload(e.currentTarget.files);
                }
              }}
              ref={inputRef}
              type="file"
            />

            {imageURL && imageURL.endsWith('.pdf') ? ( // Check if it's a PDF
              <CardMedia
                className="cursor-pointer pdf-margin"
                disabled={!ready}
                htmlFor={`card-${entity}-uploader`}
                onClick={() => {
                  if (imageURL && openCard) {
                    openCard(imageURL, t(`cards.types.${entity}`));
                  } else {
                    inputRef.current.click();
                  }
                }}
                sixteenByNine
                style={{
                  backgroundImage: 'none', // Remove background image
                  margin: '5vh 0',
                  width: '100%',
                  padding: '0',
                  borderRadius: 0,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Document
                    file={imageURL}
                    loading="Avataan PDF..."
                    noData="Tiedostoa ei löytynyt"
                    error="Tiedoston avaus epäonnistui"
                  >
                    <Page pageNumber={1} />
                  </Document>
                </div>
              </CardMedia>
            ) : (
              <CardMedia
                className="cursor-pointer"
                disabled={!ready}
                htmlFor={`card-${entity}-uploader`}
                onClick={() => {
                  if (imageURL && openCard) {
                    openCard(imageURL, t(`cards.types.${entity}`));
                  } else {
                    inputRef.current.click();
                  }
                }}
                sixteenByNine={entity !== 'ssgCard'}
                square={entity === 'ssgCard'}
                style={{
                  backgroundImage: `url(${imageURL || noCard})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  margin: '5vh 0',
                  width: '100%',
                  padding: '0',
                  borderRadius: 0,
                }}
              >
                {imageURL == null && (
                  <CardMediaContent>
                    <Typography
                      use="overline"
                      theme="textPrimaryOnDark"
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        right: '0',
                        width: '100%',
                        padding: '0',
                      }}
                    >
                      {t('cards.addImage')}
                    </Typography>
                  </CardMediaContent>
                )}
              </CardMedia>
            )}

            {handleProgress(uploads, t)}
          </>
        );
      }}

      required
    />
  );
};

// Account id is who the user belongs to
// Entity is the card type. (First aid, hot work etc.)
// NOTE: At the time of writing this is used in the registration ONLY
const addCard = (accountId, entity, t, entityRef, onBlur, onChange, confirmDelete, openCard, hasInputs = true) => {
  // TODO: Hardcoded for account ids 7, 10 and 3 (10 and 3 being test accounts)
  if (entity === 'roadSafetyCard' && accountId !== 7 && accountId !== 10 && accountId !== 3) {
    return null;
  }
  // TODO: Hardcoded for account ids 1, 7, 10, 3 and 42 (10 and 3 being test accounts)
  if (entity === 'ssgCard' && accountId !== 1 && accountId !== 7 && accountId !== 10 && accountId !== 3 && accountId !== 42 && accountId !== 49 && accountId !== 58 && accountId !== 159) {
    return null;
  }
  // TODO: Hardcoded for account ids 7, 10, 3 and 42 (10 and 3 being test accounts)
  if (entity === 'id06Card' && accountId !== 7 && accountId !== 10 && accountId !== 3 && accountId !== 42) {
    return null;
  }
  // TODO: Hardcoded for account ids 1, 10 and 3 (10 and 3 being test accounts)
  if (entity === 'machineryPermit' && accountId !== 1 && accountId !== 10 && accountId !== 3) {
    return null;
  }

  // TODO: Hardcoded for account ids 159, 10 and 3 (10 and 3 being test accounts)
  if (entity === 'tankSafetyCard' && accountId !== 159 && accountId !== 10 && accountId !== 3) {
    return null;
  }

  return (
    <Card
      key={`profile-card-${entity}`}
      style={{ boxShadow: 'none' }}
    >
      <CardPrimaryAction>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 10px',
            margin: '16.6px 0', // Match the h2's margin used in other titles on the profile page
          }}
          className="headline"
        >
          <Typography use="headline6" tag="div">
            {t(`cards.types.${entity}`)}
          </Typography>
          {entityRef[entity]?.imageURL && confirmDelete && (
            <Icon
              icon="delete_outlined"
              style={{ color: '#c7c9cc', alignSelf: 'center', width: '24px' }}
              onClick={() => confirmDelete(entity)}
            />
          )}
        </div>

        <ListItem
          style={{ height: 'auto' }}
          {...{
          }}
        >
          {(
            entity === 'firstAidTraining' && (
              <Select
                {...{
                  className: 'mdc-theme--secondary-bg pk-select profile-input-95-view-width',
                  label: t('cards.details.firstAidTraining.class'),
                  onBlur: (event) => onBlur(event, entity, 'class'),
                  onChange: (event) => onChange(event, entity, 'class'),
                  onInput: (event) => {
                    if (onChange(event, entity, 'class')) {
                      onBlur(event, entity, 'class');
                    }
                  },
                  defaultValue: entityRef[entity].class,
                  options: {
                    '-': t('cards.details.firstAidTraining.noClass'),
                    '4t': t('cards.details.firstAidTraining.4t'),
                    '8t': t('cards.details.firstAidTraining.8t'),
                    EA1: t('cards.details.firstAidTraining.EA1'),
                    EA2: t('cards.details.firstAidTraining.EA2'),
                  },
                  rootProps: { ripple: false },
                  value: entityRef[entity].class || '-',
                }}
              />
            )
          ) || (
            hasInputs && (
              <TextField
                {...{
                  className: 'profile-input-95-view-width',
                  label: t('cards.number'),
                  onBlur: (event) => onBlur(event, entity, 'number'),
                  onChange: (event) => onChange(event, entity, 'number'),
                  rootProps: { ripple: true },
                  theme: ['textPrimaryOnDark', 'secondaryBg'],
                  value: entityRef[entity].number || '',
                  style: { height: 'auto' },
                }}
              />
            )
          )}
        </ListItem>

        {hasInputs && (
          <ListItem style={{ marginTop: '2vh', height: 'auto' }}>
            <TextField
              {...{
                className: 'move-next-errormsg profile-input-95-view-width',
                label: `${t('cards.validThrough')} ${t('cards.validThroughFormat')}`,
                onBlur: (event) => onBlur(event, entity, 'validThrough'),
                onChange: (event) => onChange(event, entity, 'validThrough'),
                title: 'Syötä voimassaolo muodossa kk/vvvv',
                // pattern: '^((0[1-9]|1[0-2])|([0-9]))/?([0-9]{4}|[0-9]{2})$',
                // /\A((0[1-9]|1[0-2]))\/([2][0-9]{3})\z/
                // This pattern is basically the same as user.rb's validation pattern, only slightly different to make it work in js
                pattern: '^((0[1-9]|1[0-2]))/([2][0-9]{3})$',
                rootProps: { ripple: true },
                theme: ['textPrimaryOnDark', 'secondaryBg'],
                type: 'text',
                value: entityRef[entity].validThrough || '',
                // style: { height: 'auto' },
              }}
            />
          </ListItem>
        )}

        { addCardImage(t, entityRef, entity, React.createRef(), onBlur, openCard) }
      </CardPrimaryAction>
    </Card>
  );
};

// Account id is the entity profile belongs to.
// Entity is the card type. (First aid, hot work etc.)
// Maybe set a variable for validity that determines if:
//    1. If the graphic exists in the CollapsibleList's handle or not (no card yet added)
//    2. What color the graphic is (red, yellow, green, grey if validThrough is null?)
const addCollapsibleCard = (accountId, t, entity, entityRef, onBlur, onChange, openCard, confirmDelete, mode = null) => {
  // TODO: Hardcoded for account ids 7, 10 and 3 (10 and 3 being test accounts)
  if (entity === 'roadSafetyCard' && accountId !== 7 && accountId !== 10 && accountId !== 3) {
    return null;
  }
  // TODO: Hardcoded for account ids 1, 7, 10 and 3 (10 and 3 being test accounts)
  if (entity === 'ssgCard' && accountId !== 1 && accountId !== 7 && accountId !== 10 && accountId !== 3 && accountId !== 42 && accountId !== 49 && accountId !== 58 && accountId !== 159) {
    return null;
  }
  // TODO: Hardcoded for account ids 7, 10 and 3 (10 and 3 being test accounts)
  if (entity === 'id06Card' && accountId !== 7 && accountId !== 10 && accountId !== 3 && accountId !== 42) {
    return null;
  }
  // TODO: Hardcoded for account ids 1, 10 and 3 (10 and 3 being test accounts)
  if (entity === 'machineryPermit' && accountId !== 1 && accountId !== 10 && accountId !== 3) {
    return null;
  }

  const validThrough = entityRef[entity]?.validThrough;

  return (
    <CollapsibleList
      // NOTE: Due to a RMWC bug we can't assign a className to CollapsibleList
      key={entity}
      // style={{ margin: '10px 0' }}
      handle={(
        <SimpleListItem
          className="collapsible-card-handle mdc-typography--headline6 mdc-card"
          metaIcon="chevron_right"
          style={{ flexDirection: 'row-reverse', margin: '5px 0' }}
        >
          <div style={{ color: 'white', width: '100%' }}>
            <div style={{ color: 'white', width: '100%', display: 'flex' }}>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className="collapsible-card-handle-title">
                  {t(`cards.types.${entity}`)}
                </div>
                <div style={{ display: 'flex', paddingRight: '5px', marginTop: '-5px' }}>
                  {validThrough ? (
                    <>
                      <span className="mdc-list-item__secondary-text">{Validity.renderValidityDate(validThrough, mode)}</span>
                      <span className="mdc-list-item__secondary-text" style={{ marginLeft: '20px' }}>{Validity.renderValidityDuration(validThrough, mode)}</span>
                    </>
                  ) : (
                    <span
                      className="mdc-list-item__secondary-text"
                      // Color from theme: text hint on dark (used in WorkOrderItem)
                      style={{ color: 'var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5))' }}
                    >
                      Syötä tiedot
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </SimpleListItem>
      )}
      onOpen={() => console.log('open')}
      onClose={() => console.log('close')}
    >
      <div style={{ margin: '10px 0' }}>
        <ListItem style={{ height: 'auto' }}>
          <div className={mode === 'employer' ? 'input-with-delete-button-employer-wrapper' : 'input-with-delete-button-wrapper'}>
            <TextField
              {...{
                className: mode === 'employer' ? 'move-next-errormsg input-with-delete-button input-with-delete-button-employer' : 'profile-input-95-view-width move-next-errormsg input-with-delete-button',
                label: `${t('cards.validThrough')} ${t('cards.validThroughFormat')}`,
                onBlur: (event) => onBlur(event, entity, 'validThrough'),
                onChange: (event) => onChange(event, entity, 'validThrough'),
                title: 'Syötä voimassaolo muodossa kk/vvvv',
                // pattern: '^((0[1-9]|1[0-2])|([0-9]))/?([0-9]{4}|[0-9]{2})$',
                // /\A((0[1-9]|1[0-2]))\/([2][0-9]{3})\z/
                // This pattern is basically the same as user.rb's validation pattern, only slightly different to make it work in js
                pattern: '^((0[1-9]|1[0-2]))/([2][0-9]{3})$',
                rootProps: { ripple: true },
                theme: mode !== 'employer' && ['textPrimaryOnDark', 'secondaryBg'],
                type: 'text',
                value: entityRef[entity].validThrough || '',
              }}
            />
            <Icon
              icon="delete_outline"
              style={{
                fontSize: '25px',
                width: '35px',
                height: '25px',
                margin: 'auto 0',
              }}
              onClick={(e) => {
                e.stopPropagation();
                confirmDelete(entity);
              }}
              role="button"
            />
          </div>
        </ListItem>

        <ListItem
          style={{
            marginTop: '2vh',
            height: 'auto',
            marginLeft: mode === 'employer' && '9px',
            marginRight: mode === 'employer' && '9px',
          }}
        >
          {(
            entity === 'firstAidTraining' && (
              <Select
                {...{
                  className: mode === 'employer' ? 'profile-input-95-view-width' : 'mdc-theme--secondary-bg pk-select profile-input-95-view-width',
                  label: t('cards.details.firstAidTraining.class'),
                  onBlur: (event) => onBlur(event, entity, 'class'),
                  onChange: (event) => onChange(event, entity, 'class'),
                  onInput: (event) => {
                    if (onChange(event, entity, 'class')) {
                      onBlur(event, entity, 'class');
                    }
                  },
                  defaultValue: entityRef[entity].class,
                  options: {
                    '-': t('cards.details.firstAidTraining.noClass'),
                    '4t': t('cards.details.firstAidTraining.4t'),
                    '8t': t('cards.details.firstAidTraining.8t'),
                    EA1: t('cards.details.firstAidTraining.EA1'),
                    EA2: t('cards.details.firstAidTraining.EA2'),
                  },
                  rootProps: { ripple: false },
                  value: entityRef[entity].class || '-',
                }}
              />
            )
          ) || (
            <TextField
              {...{
                className: 'profile-input-95-view-width',
                style: { height: 'auto' },
                label: t('cards.number'),
                onBlur: (event) => onBlur(event, entity, 'number'),
                onChange: (event) => onChange(event, entity, 'number'),
                rootProps: { ripple: true },
                theme: mode !== 'employer' && ['textPrimaryOnDark', 'secondaryBg'],
                value: entityRef[entity].number || '',
              }}
            />
          )}
        </ListItem>

        <div className="collapsible-card-image-wrapper">
          <Card>
            { addCardImage(t, entityRef, entity, React.createRef(), onBlur, openCard) }
          </Card>
        </div>
      </div>
    </CollapsibleList>
  );
};

const addAttachedCardImage = (t, entityRef, entity, inputRef, onBlur, customEndpoint, openCard) => {
  let fileName;
  return (
    <DirectUploadProvider
      directUploadsPath="/rails/active_storage/direct_uploads"
      multiple={false}

      onSuccess={async (newData) => {
        if (Array.isArray(newData)) {
          const [value] = newData;
          // eslint-disable-next-line no-param-reassign
          entityRef[entity] = `/rails/active_storage/blobs/${value}/${fileName}`.split(' ').join('_');

          /* _hitEndpointWithSignedIds start */
          /* Copied the implementation from ActiveStorageProvider's source with modifications to attach the tax img to user */
          const {
            protocol,
            host,
            port,
            path,
            method,
            attribute,
            model,
          } = customEndpoint;
          const body = {
            [model.toLowerCase()]: {
              // [attribute]: multiple ? signedIds : signedIds[0],
              [attribute]: newData[0],
            },
          };

          await fetch(buildUrl({
            protocol,
            host,
            port,
            path,
          }), {
            credentials: 'same-origin',
            method,
            body: JSON.stringify(body),
            // eslint-disable-next-line no-undef
            headers: new Headers({
              accept: 'application/json',
              'content-type': 'application/json',
            }),
          });
          /* _hitEndpointWithSignedIds end */

          onBlur(null, entity, 'imageURL');
        }
      }}

      render={({ handleUpload, uploads, ready }) => {
        const imageURL = entityRef?.[entity];
        return (
          <>
            <input
              className="inputfile"
              disabled={!ready}
              id={`card-${entity}-uploader`}
              multiple={false}
              onChange={(e) => {
                if (e.currentTarget.files) {
                  [{ name: fileName }] = e.currentTarget.files;
                  handleUpload(e.currentTarget.files);
                }
              }}
              ref={inputRef}
              type="file"
            />
            {!imageURL
              && (
                <CardMedia
                  className="cursor-pointer"
                  disabled={!ready}
                  htmlFor={`card-${entity}-uploader`}
                  // onClick={() => inputRef.current.click()}
                  onClick={() => {
                    if (imageURL && openCard) {
                      openCard(imageURL, t(`cards.types.${entity}`));
                    } else {
                      inputRef.current.click();
                    }
                  }}
                  sixteenByNine
                  style={{
                    backgroundImage: `url(${noCard})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    margin: '5vh 0',
                    width: '100%',
                    padding: '0',
                  }}
                >
                  <CardMediaContent>
                    <Typography
                      use="overline"
                      theme="textPrimaryOnDark"
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        right: '0',
                        width: '100%',
                        padding: '0',
                      }}
                    >
                      {entity === 'helmetImageUrl' ? t('cards.addHelmetImage') : t('cards.addImage')}
                    </Typography>
                  </CardMediaContent>
                </CardMedia>
              )}

            {imageURL && (imageURL.endsWith('.pdf') ? (
              <CardMedia
                className="cursor-pointer pdf-margin"
                disabled={!ready}
                htmlFor={`card-${entity}-uploader`}
                onClick={() => {
                  if (imageURL && openCard) {
                    openCard(imageURL, t(`cards.types.${entity}`));
                  } else {
                    inputRef.current.click();
                  }
                }}
                sixteenByNine
                style={{
                  backgroundImage: 'none', // Remove background image
                  height: '100%',
                  margin: '5vh 0',
                  width: '100%',
                  padding: '0',
                  borderRadius: 0,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Document
                    file={imageURL}
                    loading="Avataan PDF..."
                    noData="Tiedostoa ei löytynyt"
                    error="Tiedoston avaus epäonnistui"
                  >
                    <Page pageNumber={1} />
                  </Document>
                </div>
              </CardMedia>
            ) : (
              <CardMedia
                className="cursor-pointer"
                disabled={!ready}
                htmlFor={`card-${entity}-uploader`}
                // onClick={() => inputRef.current.click()}
                onClick={() => {
                  if (imageURL && openCard) {
                    openCard(imageURL, t(`cards.types.${entity}`));
                  } else {
                    inputRef.current.click();
                  }
                }}
                sixteenByNine={entity !== 'ssgCard'}
                square={entity === 'ssgCard'}
                style={{
                  backgroundImage: `url(${imageURL || noCard})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  margin: '5vh 0',
                  width: '100%',
                  padding: '0',
                }}
              />
            ))}
            {handleProgress(uploads, t)}
          </>
        );
      }}

      required
    />
  );
};

const addAttachedCard = (entity, t, entityRef, onBlur, customEndpoint, confirmDelete, openCard) => (
  <Card
    key={`profile-card-${entity}`}
    style={{ boxShadow: 'none' }}
  >
    <CardPrimaryAction>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 10px',
          marginTop: '16.6px', // Match the h2's margin used in other titles on the profile page
        }}
        className="headline"
      >
        <Typography use="headline6" tag="div">
          {t(`cards.types.${entity}`)}
        </Typography>
        {entityRef?.[entity] && confirmDelete && (
          <Icon
            icon="delete_outlined"
            style={{ color: '#c7c9cc', alignSelf: 'center', width: '24px' }}
            onClick={() => confirmDelete(entity)}
          />
        )}
      </div>
      {addAttachedCardImage(t, entityRef, entity, React.createRef(), onBlur, customEndpoint, openCard)}
    </CardPrimaryAction>
  </Card>
);


export default addCard;
export {
  addCardImage,
  addAttachedCard,
  addCollapsibleCard,
};

/* eslint-disable */
import React, { Component } from 'react'; // Fragment
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import groupArray from 'group-array';
// import { Grid, GridCell } from '@rmwc/grid';
import {
  IconButton,
  Icon,
  Grid,
  TableRow,
  TableCell,
  TableFooter,
  TextField,
  // Button,
} from '@material-ui/core';
import { get, isEqual, sortBy } from 'lodash';
import { CSVLink } from 'react-csv';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { buttonColors } from '../calendar/EmployeeCalendar';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
// import { createTheme } from '@material-ui/core/styles';
// import MUIDataTable, { ExpandButton } from 'mui-datatables';
import MUIDataTable from 'mui-datatables';
// import TableFooter from '@mui/material/TableFooter';
import ReactToPrint from 'react-to-print';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from '../utils/moment';
import ReportSettingsDialog from './ReportSettingsDialog';
import LoadingSpinner from '../shared/LoadingSpinner';
import DeepViewHeader from '../shared/DeepViewHeader';
import normalizeFileName from '../utils/NormalizeFileName';
import './report.css';

const renderDateWithStatus = (date, status) => (
  <div style={{ position: 'relative' }}>
    <span style={{ textTransform: 'capitalize' }}>
      {moment(date).format('dd DD.MM.')}
    </span>
    <div className="hidden-print" style={{ position: 'absolute', top: '-10px', right: '-10px' }}>
      {status === 'pending' && (
        <Icon style={{ color: 'var(--mdc-theme-primary)', fontSize: '20px' }}>done</Icon>
      )}
      {status === 'accepted' && (
        <Icon style={{ color: buttonColors.green.color, fontSize: '20px' }}>done_all</Icon>
      )}
      {status === 'rejected' && (
        <Icon style={{ color: 'var(--mdc-theme-primary)', fontSize: '20px' }}>info</Icon>
      )}
    </div>
  </div>
);

@inject('uiStore', 'loginStore', 't', 'employerWorkOrderStore', 'reportStore')
@observer
class BillingReports extends Component {
  // Values that contain 2 in 1 (allowances). Used both here and in HourlyBillingReports
  // Should be moved to TaxExemptTripExpense model once it is in-use
  combinationValues = ['full_half', 'full_plus_10', 'two_meals'];

  // These are a hacky way to keep track of which rows are currently rendered in the table to calculate the total sum in response to changes
  visibleRows = [];

  previousVisibleRows = [];

  constructor(props) {
    super(props);
    this._dateRangePicker = React.createRef();
    this.state = {
      reportData: null,
      settingsDialogOpen: false,
      mainColumns: [],
      // inputValue prop is used in BillingReportsHourlyIndex context, where this BillingReport doesn't have its own date picker
      inputValue: props.inputValue || '',
      from: props.from || null,
      to: props.to || null,
      totalSumRow: null,
    };
  }

  componentDidMount() {
    const {
      uiStore,
      rawReportData,
      mode,
    } = this.props;
    const workOrderId = uiStore.currentView.id;

    if (mode !== 'tabReport') {
      this.getWorkOrderReportByUser(workOrderId);
    } else if (rawReportData) {
      this.setupTable(rawReportData);
    }
  }

  componentDidUpdate(prevProps) {
    const { rawReportData, hourlyIndex, inputValue, from, to } = this.props;
    // This is triggered on the initial report tab, when the index has finished the DB query
    if (!isEqual(rawReportData, prevProps.rawReportData)) {
      this.setupTable(rawReportData);
    }

    // If the BillingReports is rendered in BillingReportsHourlyIndex, the date picker for changing inputValue isn't there
    // And we want to use BillingReportsHourlyIndex's inputValue instead
    // Meanwhile BillingReportsDailyIndex's BillingReports should use its own inputValue state
    if (hourlyIndex && (!isEqual(inputValue, prevProps.inputValue) || !isEqual(from, prevProps.from) || !isEqual(to, prevProps.to))) {
      this.setState({
        inputValue,
        from,
        to
      });
    }
  }

  setRangeAndWeek = (range, weekNumber = null, callback = null) => {
    const { t, uiStore, getWorkOrderReportByUserFiltered } = this.props;
    const workOrderId = uiStore.currentView.id;
    let finalValue = '';

    // Check if the range has both 'from' and 'to' values
    if (range.from && range.to) {
      const rangeFrom = moment(range.from);
      const rangeTo = moment(range.to);

      let weekNumberString = '';
      if (!weekNumber) {
        // User selected a single day, we need to find the week numbers manually
        const fromWeekNumber = rangeFrom.isoWeek();
        const toWeekNumber = rangeTo.isoWeek();
        // We do not want to add the '-' if there's no second different week number
        const toWeekNumberString = toWeekNumber && toWeekNumber !== fromWeekNumber ? `-${toWeekNumber}` : '';
        weekNumberString = `${t('resource_availability.week_prefix')}${fromWeekNumber + toWeekNumberString}`;
      } else {
        // User selected a week by clicking on the number
        weekNumberString = `${t('resource_availability.week_prefix')}${weekNumber}`;
      }

      const dayRange = `${rangeFrom.format('dd DD.MM.YYYY')} - ${rangeTo.format('dd DD.MM.YYYY')}`;
      finalValue = `${weekNumberString} | ${dayRange}`;
    } else {
      finalValue = 'Valitse päivät'; // Default value if range is not set
    }

    // Update component state with the final values
    this.setState({
      inputValue: finalValue,
      from: range.from,
      to: range.to,
    }, () => {
      // If both 'from' and 'to' values are available, call the corresponding function
      if (range.from && range.to) {
        const formattedFrom = moment(range.from).format('YYYY-MM-DD');
        const formattedTo = moment(range.to).format('YYYY-MM-DD');
        getWorkOrderReportByUserFiltered(workOrderId, formattedFrom, formattedTo);
      }

      // Call the optional callback function if provided
      if (callback) {
        callback();
      }
    });
  }

  calculateSumWorkHour(data, key) {
    const sumTypes = {
      'allowance': 'countSeparate',
      'date': 'none',
      'time': 'none',
      'timeNormal': 'add',
      'description': 'none',
      'latestEmployeeVersion': 'none',
      'tripRoutes': 'none',
    };

    let fullAllowanceCount = 0;
    let halfAllowanceCount = 0;
    let mealAllowancesCount = 0;
    let abroadAllowanceCount = 0;
    let unknownAllowanceCount = 0;

    let summingData = data;
    if (key) {
      summingData = data.filter((row) => row.user.fullName === key);
    }

    const sumWorkHour = summingData.reduce((acc, dataRow) => {
      Object.keys(dataRow.workHour).forEach((attr) => {
        // acc[attr] = (acc[attr] || 0) + dataRow.workHour[attr];

        if (sumTypes[attr] === 'none') {
          acc[attr] = null;
          return acc;
        }

        if (attr === 'allowance' && dataRow.workHour[attr]) {
          const allowance = dataRow.workHour[attr];

          if (allowance === 'half') {
            halfAllowanceCount += 1;
          } else if (allowance === 'full') {
            fullAllowanceCount += 1;
          } else if (allowance === 'full_plus_10') {
            fullAllowanceCount += 2;
          } else if (allowance === 'full_half') {
            fullAllowanceCount += 1;
            halfAllowanceCount += 1;
          } else if (allowance === 'full_plus_2') {
            halfAllowanceCount += 1;
          } else if (allowance === 'two_meals') {
            mealAllowancesCount += 2;
          } else if (allowance === 'meal') {
            mealAllowancesCount += 1;
          } else if (allowance === 'es' || allowance === 'se' || allowance === 'fr' || allowance === 'pt') {
            abroadAllowanceCount += 1;
          } else if (allowance === 'se_double') {
            abroadAllowanceCount += 2;
          } else if (allowance !== null) {
            unknownAllowanceCount += 1;
          }
        }

        if (sumTypes[attr] === 'countSeparate') {
          acc[attr] = (
            <div className="allowance-sums">
              {fullAllowanceCount > 0 && <div>{`${fullAllowanceCount}x Koko`}</div>}
              {halfAllowanceCount > 0 && <div>{`${halfAllowanceCount}x Osa`}</div>}
              {mealAllowancesCount > 0 && <div>{`${mealAllowancesCount}x Ateria`}</div>}
              {abroadAllowanceCount > 0 && <div>{`${abroadAllowanceCount}x Ulkomaat`}</div>}
              {unknownAllowanceCount > 0 && <div>{`${unknownAllowanceCount}x Muut`}</div>}
            </div>
          );
        }

        // if (dataRow.workHour[attr] && !acc[attr]) {
        //   acc[attr] = 0;
        // }

        // Add the values together
        if (sumTypes[attr] === 'add' || !sumTypes[attr]) {
          const newValue = (acc[attr] || 0) + (dataRow.workHour[attr] || 0);
          acc[attr] = newValue === 0 ? null : newValue;
        }

        if (sumTypes[attr] === 'count') {
          // Count the number of values
          // rows.forEach((row) => {
          //   if (row.workHour[columnName] && columnName === 'allowance' && this.combinationValues.includes(row.workHour[columnName])) {
          //     sum += 2;
          //   } else {
          //     sum += row.workHour[columnName] ? 1 : 0;
          //   }
          // });
          if (dataRow.workHour[attr] && attr === 'allowance' && this.combinationValues.includes(dataRow.workHour[attr])) {
            // acc[attr] += 2;
            acc[attr] = (acc[attr] || 0) + 2;
          } else {
            // acc[attr] += dataRow.workHour[attr] ? 1 : 0;
            acc[attr] = (acc[attr] || 0) + (dataRow.workHour[attr] ? 1 : 0);
          }
        }
      });

      return acc;
    }, {});

    return sumWorkHour;
  }

  renderTableV2() {
    // const { uiStore, t } = this.props;
    // const columns = ["name", "title", "groupingColumn"];
    const { isLoading } = this.props;
    const { reportData, mainColumns, isLoading: stateIsLoading } = this.state;

    if (isLoading || stateIsLoading) {
      return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <LoadingSpinner color="black" />
        </div>
      )
    }

    const options = {
      filter: true,
      filterType: 'multiselect',
      responsive: 'standard',
      search: false,
      viewColumns: false,
      selectableRows: 'none',
      fixedHeader: true,
      sort: true,
      download: false,
      print: false,
      pagination: false,
      enableNestedDataAccess: '.',
      setRowProps: (row) => {
        // isGroup === true
        if (row[0] === true) {
          return {
            style: {
              backgroundColor: "#cecece"
            },
          };
        }
      },
      customSort: (data, colIndex, order) => {
        // ChatGPT 6.11.2023
        // The table is already initially grouped and sorted by user.fullName (row where isGroup = true)
        // The idea is to only sort the normal rows where isGroup = false while keeping the group row order same
        let result = [];
        let currentGroup = [];

        data.forEach((item, index) => {
          const isGroup = item.data[0];

          if (isGroup) {
            if (currentGroup.length > 0) {
              // Sort the previous group by the specified key, skip the first item (isGroup: true)
              currentGroup = [
                currentGroup[0],
                ...currentGroup.slice(1).sort((a, b) =>
                  order === 'asc' ? a.data[colIndex] - b.data[colIndex] : b.data[colIndex] - a.data[colIndex]
                )];
              result = result.concat(currentGroup);
              currentGroup = [];
            }
            // Start a new group
            currentGroup.push(item);
          } else {
            currentGroup.push(item);
          }

          // If it's the last item, sort and add the last group
          if (index === data.length - 1) {
            currentGroup = [
              currentGroup[0],
              ...currentGroup.slice(1).sort((a, b) =>
                order === 'asc' ? a.data[colIndex] - b.data[colIndex] : b.data[colIndex] - a.data[colIndex]
              )];
            result = result.concat(currentGroup);
          }
        });

        return result;
      },
      onFilterChange: (_changedColumn, _filterList, _type, _changedColumnIndex, displayData) => {
        const { hideUserCol } = this.props;
        const { reportData, originalData } = this.state;

        if (!displayData) {
          // Assuming filter reset
          return;
        }

        // Because displayData only gives us the indexes of rows that are current visible (from total data),
        // and because we need the original data to use calculateSumWorkHour(),
        // we map the displayData indexes to the reportData rows, which are then used to find the correct raw data rows from originalData used to calculate sum
        const visibleRowIndexes = displayData.map((row) => row.dataIndex);

        const visibleOriginalData = [];
        visibleRowIndexes.forEach((index) => {
          // Find the actual data of the visible row using the displayData index
          const visibleReportDataRow = reportData[index];
          // Find the matching original data row's workHour
          const foundVisibleOriginalData = originalData.workHours.find((ogDataRow) => ogDataRow.workHour.id === visibleReportDataRow.workHour.id);
          if (foundVisibleOriginalData) {
            // Do not push if the row already exists
            if (!visibleOriginalData.find((row) => row.workHour.id === foundVisibleOriginalData.workHour.id)) {
              visibleOriginalData.push(foundVisibleOriginalData);
            }
          }
        });

        const newReportData = [...reportData];

        // Calculate the sum
        // A lite version of setupTable()
        let newTotalSumRow;
        if (!hideUserCol) {
          const finalSum = this.calculateSumWorkHour(visibleOriginalData);
          newTotalSumRow = {
            isGroup: true,
            user: {
              fullName: 'Yhteensä',
            },
            workHour: { ...finalSum, id: null },
          };

          const totalSumRowIndex = reportData.findIndex((row) => row.isGroup && row.user.fullName === 'Yhteensä');

          newReportData[totalSumRowIndex] = newTotalSumRow;
        }

        this.setState({
          reportData: newReportData,
          totalSumRow: newTotalSumRow,
        });
      },
      textLabels: {
        body: {
          noMatch: 'Kirjauksia ei löytynyt',
        },
        toolbar: {
          search: 'Haku',
          viewColumns: 'Näytä sarakkeet',
          filterTable: 'Suodatin',
        },
        pagination: {
          next: "Seuraava sivu",
          previous: "Edellinen",
          rowsPerPage: "Rivejä sivulla:",
          displayRows: "/",
        },
        filter: {
          all: "Kaikki",
          title: "SUODATIN",
          reset: "Tyhjennä",
        },
        viewColumns: {
          title: "Näytä sarakkeet",
          titleAria: "Näytä/piilota sarakkeet",
        },
        selectedRows: {
          text: "rivi(ä) valittu",
          delete: "Poista",
          deleteAria: "Poista valitut rivit",
        },
      },
    };

    // return <MUIDataTable data={finalData} columns={columns} options={options} />;
    return <MUIDataTable data={reportData} columns={mainColumns} options={options} />;
  };

  setupTable(rawReportData) {
    const processedData = this.processDataForTableV2(rawReportData);
    const groupedData = processedData.groupedData;
    const newTotalSumRow = processedData.newTotalSumRow;
    const mainColumns = this.getMainColumnsV2(groupedData);

    // Update the component's state with the mainColumns, groupedEntries, and reportWorkOrder.
    this.setState({
      mainColumns,
      reportData: groupedData,
      reportWorkOrder: rawReportData.workOrder,
      totalSumRow: newTotalSumRow,
      originalData: rawReportData,
    }, () => {
      // Hide any empty columns in the table.
      this.hideEmptyColumnsV2(groupedData);
    });
  }

  getMainColumnsV2(finalData) {
    const { uiStore, t } = this.props;

    const defaultColumns = [
      {
        name: 'isGroup',
        label: 'isGroup',
        options: {
          display: 'excluded',
          filter: false,
          viewColumns: false,
        }
      },
      // {
      //   name: 'workHour.status',
      //   label: 'Tila',
      //   options: {
      //     display: 'excluded',
      //     filter: true,
      //   },
      // },
      {
        name: 'user.fullName',
        label: 'Työntekijä',
        options: {
          customBodyRenderLite: (dataIndex) => {
            // if visibleRows does not contain row - push
            if (!finalData[dataIndex].isGroup && !this.visibleRows.find((row) => row.workHour.id === finalData[dataIndex].workHour.id)) {
              this.visibleRows.push(finalData[dataIndex]);
            }
            if (!finalData[dataIndex].isGroup) {
              return null;
            }

            return finalData[dataIndex]?.user.fullName;
          },
          sort: false,
          filter: true,
        }
      },
      {
        name: 'workHour.date',
        label: 'Pvm',
        options: {
          display: true,
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const workHour = finalData[dataIndex]?.workHour;
            if (finalData[dataIndex].isGroup) {
              return null;
            }

            const result = this.renderCustomTooltip(
              `${workHour?.id}-statusHover`,
              renderDateWithStatus(workHour?.date, workHour?.status),
              this.renderStatusTextWithComment(workHour?.status, workHour?.employerComment),
              null,
              '65px',
            );

            return (
              result
            );
          },
          setCellHeaderProps: () => ({ style: { maxWidth: '40px', minWidth: '40px', width: '40px' } }),
          sortDirection: 'asc',
        },
      },
      {
        name: 'time',
        label: 'Klo',
        options: {
          display: true,
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];

            // Is group or not
            if (row.isGroup) {
              return null;
            } else {
              return (
                // <span>{`${reportData[dataIndex].user.firstName} ${reportData[dataIndex].user.lastName}`}</span>
                <span style={{ whiteSpace: 'nowrap' }}>
                  {row.workHour.from || '?'}
                  -
                  {row.workHour.to || '?'}
                </span>
              );
            }
          },
          setCellHeaderProps: () => ({ style: { maxWidth: '70px', minWidth: '70px', width: '70px' } }),
        },
      },
      {
        name: 'workHour.timeNormal',
        label: 'Normaali',
        headerWidth: '90.5px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.timeNormal');
          },
        },
        // exclude / disable according to account id here?
      },
      {
        name: 'workHour.time50',
        label: '+50%',
        headerWidth: '66.6px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.time50');
          },
        },
      },
      {
        name: 'workHour.time100',
        label: '+100%',
        headerWidth: '74.7px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.time100');
          },
        },
      },
      {
        name: 'workHour.timeTotal',
        label: 'Työ\u00ADtunnit',
        headerWidth: '75px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.timeTotal');
          },
        },
      },
      {
        name: 'workHour.travelTime',
        label: 'Matka\u00ADtunnit työajan ulkop.',
        headerWidth: '109.27px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.travelTime');
          },
        },
      },
    ];

    const accountId1Cols = [
      {
        name: 'workHour.driveTime',
        label: 'Matka\u00ADtunnit työ\u00ADajalla',
        headerWidth: '109.27px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.driveTime');
          },
        },
      },
      {
        name: 'workHour.driveTime50',
        label: 'Matka\u00ADtunnit 50%',
        headerWidth: '109.27px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.driveTime50');
          },
        },
      },
      {
        name: 'workHour.driveTime100',
        label: 'Matka\u00ADtunnit 100%',
        headerWidth: '109.27px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.driveTime100');
          },
        },
      },
      {
        name: 'workHour.kms',
        label: 'KM oma\u00AD auto',
        headerWidth: '60.38px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kms');
          },
        },
      },
      {
        name: 'workHour.kmsPerson',
        label: 'KM + 1 hlö',
        headerWidth: '53.39px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsPerson');
          },
        },
      },
      {
        name: 'workHour.kmsService',
        label: 'KM Huolto-auto',
        headerWidth: '92.7px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsService');
          },
        },
      },
      {
        name: 'workHour.allowance',
        label: 'Päiväraha',
        headerWidth: '95.41px',
        sumType: 'countSeparate',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            const allowance = this.renderSumRowValue(row, 'workHour.allowance');

            if (finalData[dataIndex].isGroup) {
              return allowance;
            } else {
              return <span>{allowance && t(`timelog.attributes.${allowance}_short`)}</span>;
            }
          },
          filter: false,
        },
      },
      {
        name: 'workHour.sundayWork',
        label: 'Su-korvaus',
        headerWidth: '83px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.sundayWork');
          },
        },
      },
      {
        name: 'workHour.weeklyRest',
        label: 'Vko-lepo\u00ADkorvaus',
        headerWidth: '111px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.weeklyRest');
          },
        },
      },
      {
        name: 'workHour.eveningShift',
        label: 'Ilta\u00ADlisä',
        headerWidth: '75px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.eveningShift');
          },
        },
      },
      {
        name: 'workHour.nightShift',
        label: 'Yö\u00ADlisä',
        headerWidth: '72px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.nightShift');
          },
        },
      },
      {
        name: 'workHour.emergencyWork',
        label: 'Hälytys\u00ADraha',
        headerWidth: '108px',
        sumType: 'count',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            const emergencyWork = this.renderSumRowValue(row, 'workHour.emergencyWork');

            if (emergencyWork && !row.isGroup) {
              return 'Kyllä';
            } else {
              return emergencyWork;
            }
          },
        },
      },
      {
        name: 'workHour.midweekHolidayCompensation',
        label: 'Arkipyhä\u00ADkorvaus',
        headerWidth: '141px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.midweekHolidayCompensation');
          },
        },
      },
      {
        name: 'workHour.description',
        label: 'Lisätieto',
        headerWidth: '300px',
        sumType: 'count',
        options: {
          filter: false,
          customBodyRender: (value) => {
            return (
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {value}
              </span>
            );
          }
        },
      },
    ];

    const accountId7Cols = [
      {
        name: 'workHour.driveTime',
        label: 'Matka\u00ADtunnit työ\u00ADajalla',
        headerWidth: '109.27px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.driveTime');
          },
        },
      },
      {
        name: 'workHour.driveTime50',
        label: 'Matka\u00ADtunnit 50%',
        headerWidth: '109.27px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.driveTime50');
          },
        },
      },
      {
        name: 'workHour.driveTime100',
        label: 'Matka\u00ADtunnit 100%',
        headerWidth: '109.27px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.driveTime100');
          },
        },
      },
      {
        name: 'workHour.kms',
        label: 'KM oma\u00AD auto',
        headerWidth: '60.38px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kms');
          },
        },
      },
      {
        name: 'workHour.kmsPerson',
        label: 'KM + 1 hlö',
        headerWidth: '53.39px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsPerson');
          },
        },
      },
      {
        name: 'workHour.kmsTrailer',
        label: 'KM + perä\u00ADkärry',
        headerWidth: '92.7px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsTrailer');
          },
        },
      },
      {
        name: 'workHour.kmsService',
        label: 'KM Huolto-auto',
        headerWidth: '92.7px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsService');
          },
        },
      },
      {
        name: 'workHour.kmsKit',
        label: 'KM + Pakki',
        headerWidth: '53.39px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsKit');
          },
        },
      },
      {
        name: 'workHour.allowance',
        label: 'Päiväraha',
        headerWidth: '95.41px',
        sumType: 'countSeparate',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            const allowance = this.renderSumRowValue(row, 'workHour.allowance');

            if (finalData[dataIndex].isGroup) {
              return allowance;
            } else {
              return <span>{allowance && t(`timelog.attributes.${allowance}_short`)}</span>;
            }
          },
          filter: false,
        },
      },
      {
        name: 'workHour.sundayWork',
        label: 'Su-korvaus',
        headerWidth: '83px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.sundayWork');
          },
        },
      },
      {
        name: 'workHour.weeklyRest',
        label: 'Vko-lepo\u00ADkorvaus',
        headerWidth: '111px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.weeklyRest');
          },
        },
      },
      {
        name: 'workHour.eveningShift',
        label: 'Ilta\u00ADlisä',
        headerWidth: '75px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.eveningShift');
          },
        },
      },
      {
        name: 'workHour.nightShift',
        label: 'Yö\u00ADlisä',
        headerWidth: '72px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.nightShift');
          },
        },
      },
      {
        name: 'workHour.emergencyWork',
        label: 'Hälytys\u00ADraha',
        headerWidth: '108px',
        sumType: 'count',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            const emergencyWork = this.renderSumRowValue(row, 'workHour.emergencyWork');

            if (emergencyWork && !row.isGroup) {
              return 'Kyllä';
            } else {
              return emergencyWork;
            }
          },
        },
      },
      {
        name: 'workHour.midweekHolidayCompensation',
        label: 'Arkipyhä\u00ADkorvaus',
        headerWidth: '141px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.midweekHolidayCompensation');
          },
        },
      },
      {
        name: 'workHour.conditionCompensation',
        label: 'Olosuhde\u00ADlisä',
        headerWidth: '116px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.conditionCompensation');
          },
        },
      },
      {
        name: 'workHour.miningCompensation',
        label: 'Kaivos\u00ADlisä',
        headerWidth: '98px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.miningCompensation');
          },
        },
      },
      {
        name: 'workHour.description',
        label: 'Selite',
        headerWidth: '100px',
        sumType: 'count',
        options: {
          filter: false,
        },
      },
    ];

    const accountId42Cols = [
      {
        name: 'workHour.kms',
        label: 'KM oma\u00AD auto',
        headerWidth: '60.38px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kms');
          },
        },
      },
      {
        name: 'workHour.kmsPerson',
        label: 'KM + 1 hlö',
        headerWidth: '53.39px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsPerson');
          },
        },
      },
      {
        name: 'workHour.kmsService',
        label: 'KM Huolto\u00ADauto',
        headerWidth: '92.7px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsService');
          },
        },
      },
      {
        name: 'workHour.allowance',
        label: 'Päiväraha',
        headerWidth: '95.41px',
        sumType: 'countSeparate',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            const allowance = this.renderSumRowValue(row, 'workHour.allowance');

            if (finalData[dataIndex].isGroup) {
              return allowance;
            } else {
              return <span>{allowance && t(`timelog.attributes.${allowance}_short`)}</span>;
            }
          },
          options: {
            filter: false,
          },
        },
      },
      {
        name: 'workHour.sundayWork',
        label: 'Su-korvaus',
        headerWidth: '83px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.sundayWork');
          },
        },
      },
      {
        name: 'workHour.weeklyRest',
        label: 'Vko-lepo\u00ADkorvaus',
        headerWidth: '111px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.weeklyRest');
          },
        },
      },
      {
        name: 'workHour.eveningShift',
        label: 'Ilta\u00ADlisä',
        headerWidth: '75px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.eveningShift');
          },
        },
      },
      {
        name: 'workHour.nightShift',
        label: 'Yö\u00ADlisä',
        headerWidth: '72px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.nightShift');
          },
        },
      },
      {
        name: 'workHour.emergencyWork',
        label: 'Hälytys\u00ADraha',
        headerWidth: '108px',
        sumType: 'count',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            const emergencyWork = this.renderSumRowValue(row, 'workHour.emergencyWork');

            if (emergencyWork && !row.isGroup) {
              return 'Kyllä';
            } else {
              return emergencyWork;
            }
          },
        },
      },
      {
        name: 'workHour.midweekHolidayCompensation',
        label: 'Arkipyhä\u00ADkorvaus',
        headerWidth: '141px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.midweekHolidayCompensation');
          },
        },
      },
      {
        name: 'workHour.description',
        label: 'Selite',
        headerWidth: '100px',
        sumType: 'count',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.description');
          },
        },
      },
    ];

    // const accountId6Cols = [
    //   {
    //     name: 'workHour.allowance',
    //     label: 'Päiväraha',
    //     headerWidth: '95.41px',
    //     sumType: 'countSeparate',
    //     options: {
    //       customBodyRender: (value, tableMeta) => {
    //         if (tableMeta.rowData[0]) {
    //           return value;
    //         } else {
    //           return <span>{value && t(`timelog.attributes.${value}_short`)}</span>;
    //         }
    //       },
    //       options: {
    //         filter: false,
    //       },
    //     },
    //   },
    //   {
    //     name: 'workHour.sundayWork',
    //     label: 'Su-korvaus',
    //     headerWidth: '83px',
    //     sumType: 'add',
    //     options: {
    //       filter: false,
    //     },
    //   },
    //   {
    //     name: 'workHour.weeklyRest',
    //     label: 'Vko-lepo\u00ADkorvaus',
    //     headerWidth: '111px',
    //     sumType: 'add',
    //     options: {
    //       filter: false,
    //     },
    //   },
    //   {
    //     name: 'workHour.eveningShift',
    //     label: 'Ilta\u00ADlisä',
    //     headerWidth: '75px',
    //     sumType: 'add',
    //     options: {
    //       filter: false,
    //     },
    //   },
    //   {
    //     name: 'workHour.nightShift',
    //     label: 'Yö\u00ADlisä',
    //     headerWidth: '72px',
    //     sumType: 'add',
    //     options: {
    //       filter: false,
    //     },
    //   },
    //   {
    //     name: 'workHour.emergencyWork',
    //     label: 'Hälytys\u00ADraha',
    //     headerWidth: '108px',
    //     sumType: 'count',
    //     options: {
    //       filter: false,
    //       customBodyRender: (value, tableMeta) => {
    //         if (value && !tableMeta.rowData[0]) {
    //           return 'Kyllä';
    //         } else {
    //           return value;
    //         }
    //       },
    //     },
    //   },
    //   {
    //     name: 'workHour.midweekHolidayCompensation',
    //     label: 'Arkipyhä\u00ADkorvaus',
    //     headerWidth: '141px',
    //     sumType: 'add',
    //     options: {
    //       filter: false,
    //     },
    //   },
    //   {
    //     name: 'workHour.description',
    //     label: 'Selite',
    //     headerWidth: '100px',
    //     sumType: 'count',
    //     options: {
    //       filter: false,
    //     },
    //   },
    // ];

    const accountId49Cols = [
      {
        name: 'workHour.driveTime',
        label: 'Matka\u00ADtunnit työ\u00ADajalla',
        headerWidth: '109.27px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.driveTime');
          },
        },
      },
      {
        name: 'workHour.driveTime50',
        label: 'Matka\u00ADtunnit 50%',
        headerWidth: '109.27px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.driveTime50');
          },
        },
      },
      {
        name: 'workHour.driveTime100',
        label: 'Matka\u00ADtunnit 100%',
        headerWidth: '109.27px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.driveTime100');
          },
        },
      },
      {
        name: 'workHour.kms',
        label: 'KM oma\u00AD auto',
        headerWidth: '60.38px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kms');
          },
        },
      },
      {
        name: 'workHour.kmsPerson',
        label: 'KM + 1 hlö',
        headerWidth: '53.39px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsPerson');
          },
        },
      },
      {
        name: 'workHour.kmsTrailer',
        label: 'KM + perä\u00ADkärry',
        headerWidth: '92.7px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsTrailer');
          },
        },
      },
      {
        name: 'workHour.kmsService',
        label: 'KM Huolto-auto',
        headerWidth: '92.7px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsService');
          },
        },
      },
      {
        name: 'workHour.allowance',
        label: 'Päiväraha',
        headerWidth: '95.41px',
        sumType: 'countSeparate',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            const allowance = this.renderSumRowValue(row, 'workHour.allowance');

            if (finalData[dataIndex].isGroup) {
              return allowance;
            } else {
              return <span>{allowance && t(`timelog.attributes.${allowance}_short`)}</span>;
            }
          },
          filter: false,
        },
      },
      {
        name: 'workHour.sundayWork',
        label: 'Su-korvaus',
        headerWidth: '83px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.sundayWork');
          },
        },
      },
      {
        name: 'workHour.weeklyRest',
        label: 'Vko-lepo\u00ADkorvaus',
        headerWidth: '111px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.weeklyRest');
          },
        },
      },
      {
        name: 'workHour.eveningShift',
        label: 'Ilta\u00ADlisä',
        headerWidth: '75px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.eveningShift');
          },
        },
      },
      {
        name: 'workHour.nightShift',
        label: 'Yö\u00ADlisä',
        headerWidth: '72px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.nightShift');
          },
        },
      },
      {
        name: 'workHour.emergencyWork',
        label: 'Hälytys\u00ADraha',
        headerWidth: '108px',
        sumType: 'count',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            const emergencyWork = this.renderSumRowValue(row, 'workHour.emergencyWork');

            if (emergencyWork && !row.isGroup) {
              return 'Kyllä';
            } else {
              return emergencyWork;
            }
          },
        },
      },
      {
        name: 'workHour.midweekHolidayCompensation',
        label: 'Arkipyhä\u00ADkorvaus',
        headerWidth: '141px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.midweekHolidayCompensation');
          },
        },
      },
      {
        name: 'workHour.conditionCompensation',
        label: 'Olosuhde\u00ADlisä',
        headerWidth: '116px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.conditionCompensation');
          },
        },
      },
      {
        name: 'workHour.description',
        label: 'Lisätieto',
        headerWidth: '300px',
        sumType: 'count',
        options: {
          filter: false,
          customBodyRender: (value) => {
            return (
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {value}
              </span>
            );
          }
        },
      },
    ];

    const accountId58Cols = [
      {
        name: 'workHour.driveTime',
        label: 'Matka\u00ADtunnit työ\u00ADajalla',
        headerWidth: '109.27px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.driveTime');
          },
        },
      },
      {
        name: 'workHour.kmsService',
        label: 'KM Huolto-auto',
        headerWidth: '92.7px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsService');
          },
        },
      },
      {
        name: 'workHour.kms',
        label: 'KM oma\u00AD auto',
        headerWidth: '60.38px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kms');
          },
        },
      },
      {
        name: 'workHour.kmsPerson',
        label: '+ KM 1 hlö',
        headerWidth: '53.39px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsPerson');
          },
        },
      },
      {
        name: 'workHour.kmsHeavyLoad',
        label: 'KM + iso kuorma',
        headerWidth: '53.39px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.kmsHeavyLoad');
          },
        },
      },
      {
        name: 'workHour.allowance',
        label: 'Päiväraha',
        headerWidth: '95.41px',
        sumType: 'countSeparate',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            const allowance = this.renderSumRowValue(row, 'workHour.allowance');

            if (finalData[dataIndex].isGroup) {
              return allowance;
            } else {
              return <span>{allowance && t(`timelog.attributes.${allowance}_short`)}</span>;
            }
          },
          filter: false,
        },
      },
      {
        name: 'workHour.sundayWork',
        label: 'Su-korvaus',
        headerWidth: '83px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.sundayWork');
          },
        },
      },
      {
        name: 'workHour.weeklyRest',
        label: 'Vko-lepo\u00ADkorvaus',
        headerWidth: '111px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.weeklyRest');
          },
        },
      },
      {
        name: 'workHour.eveningShift',
        label: 'Ilta\u00ADlisä',
        headerWidth: '75px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.eveningShift');
          },
        },
      },
      {
        name: 'workHour.nightShift',
        label: 'Yö\u00ADlisä',
        headerWidth: '72px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.nightShift');
          },
        },
      },
      {
        name: 'workHour.emergencyWork',
        label: 'Hälytys\u00ADraha',
        headerWidth: '108px',
        sumType: 'count',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            const emergencyWork = this.renderSumRowValue(row, 'workHour.emergencyWork');

            if (emergencyWork && !row.isGroup) {
              return 'Kyllä';
            } else {
              return emergencyWork;
            }
          },
        },
      },
      {
        name: 'workHour.midweekHolidayCompensation',
        label: 'Arkipyhä\u00ADkorvaus',
        headerWidth: '141px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.midweekHolidayCompensation');
          },
        },
      },
      {
        name: 'workHour.conditionCompensation',
        label: 'Olosuhde\u00ADlisä 1',
        headerWidth: '116px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.conditionCompensation');
          },
        },
      },
      {
        name: 'workHour.conditionCompensationTwo',
        label: 'Olosuhde\u00ADlisä 2',
        headerWidth: '116px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.conditionCompensationTwo');
          },
        },
      },
      {
        name: 'workHour.conditionCompensationThree',
        label: 'Olosuhde\u00ADlisä 3',
        headerWidth: '116px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.conditionCompensationThree');
          },
        },
      },
      {
        name: 'workHour.conditionCompensationFour',
        label: 'Olosuhde\u00ADlisä 4',
        headerWidth: '116px',
        sumType: 'add',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workHour.conditionCompensationFour');
          },
        },
      },
      {
        name: 'workHour.description',
        label: 'Lisätieto',
        headerWidth: '300px',
        sumType: 'count',
        options: {
          filter: false,
          customBodyRender: (value) => {
            return (
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {value}
              </span>
            );
          }
        },
      },
    ];

    const accountId = uiStore.currentUser?.accountId;
    let mainColumns;
    if (accountId === 7 || accountId === 3 || accountId === 10) {
      mainColumns = accountId7Cols;
    } else if (accountId === 42 || accountId === 6) {
      mainColumns = accountId42Cols;
    } else if (accountId === 49) {
      mainColumns = accountId49Cols;
    } else if (accountId === 58) {
      mainColumns = accountId58Cols;
    } else {
      mainColumns = accountId1Cols;
    }

    return [...defaultColumns, ...mainColumns];
  }

  // Alternative method to not trigger the query immediately on calendar day/week click, only on calendar close in order to reduce unnecessary queries
  // Not in use because the daypicker onBlur prop doesn't work properly (clicking two different days in a row triggers it?)
  // closeDateRangePicker = () => {
  //   const { setRangeAndWeek: indexSetRangeAndWeek } = this.props;
  //   const { range } = this.state;

  //   this._dateRangePicker.current.hideDayPicker();

  //   if (indexSetRangeAndWeek) {
  //     // Call the hourly index setRangeAndWeek
  //     indexSetRangeAndWeek(range);
  //   } else {
  //     // Call the component's own setRangeAndWeek (daily index)
  //     this.setRangeAndWeek(range);
  //   }
  // }


  closeDateRangePicker = () => {
    this._dateRangePicker.current.hideDayPicker();
  }

  customDateRangePickerOverlay = ({ classNames, children, ...props }) => (
    <div
      className={classNames.overlayWrapper}
      {...props}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }} className={classNames.overlay}>
        {children}
        <div style={{ paddingBottom: '10px', textAlign: 'center' }}>
          <button type="button" className="accept-button mdc-dialog__button mdc-button" onClick={() => this.closeDateRangePicker()}>
            Valmis
          </button>
        </div>
      </div>
    </div>
  );

  // Alternative method to not trigger the query immediately on calendar day/week click, only on calendar close in order to reduce unnecessary queries
  // Not in use because the daypicker onBlur prop doesn't work properly (clicking two different days in a row triggers it?)
  // handleDayClick = (day) => {
  //   const { t } = this.props;
  //   const { range, from, to } = this.state;

  //   const oldRange = {
  //     from: range?.from || from,
  //     to: range?.to || to,
  //   };
  //   const newRange = DateUtils.addDayToRange(day, oldRange);

  //   let finalValue = '';

  //   if (newRange.from && newRange.to) {
  //     const rangeFrom = moment(newRange.from);
  //     const rangeTo = moment(newRange.to);

  //     let weekNumberString = '';
  //     // User selected a single day, we need to find the week numbers manually
  //     const fromWeekNumber = rangeFrom.isoWeek();
  //     const toWeekNumber = rangeTo.isoWeek();
  //     // We do not want to add the '-' if there's no second different week number
  //     const toWeekNumberString = toWeekNumber && toWeekNumber !== fromWeekNumber ? `-${toWeekNumber}` : '';
  //     weekNumberString = `${t('resource_availability.week_prefix')}${fromWeekNumber + toWeekNumberString}`;

  //     const dayRange = `${rangeFrom.format('dd DD.MM.YYYY')} - ${rangeTo.format('dd DD.MM.YYYY')}`;
  //     finalValue = `${weekNumberString} | ${dayRange}`;
  //   } else {
  //     finalValue = 'Valitse päivät'; // Default value if range is not set
  //   }

  //   this.setState({
  //     range: newRange,
  //     from: newRange.from,
  //     to: newRange.to,
  //     inputValue: finalValue,
  //   });
  // }

  // handleWeekClick = (weekNumber, days) => {
  //   const { t } = this.props;
  //   // const { setRangeAndWeek: indexSetRangeAndWeek } = this.props;

  //   const newRange = { from: days[0], to: days[6] };
  //   let finalValue = '';
  //   const rangeFrom = moment(newRange.from);
  //   const rangeTo = moment(newRange.to);

  //   const weekNumberString = `${t('resource_availability.week_prefix')}${weekNumber}`;
  //   const dayRangeString = `${rangeFrom.format('dd DD.MM.YYYY')} - ${rangeTo.format('dd DD.MM.YYYY')}`;
  //   finalValue = `${weekNumberString} | ${dayRangeString}`;

  //   this.setState({
  //     range: newRange,
  //     from: newRange.from,
  //     to: newRange.to,
  //     inputValue: finalValue,
  //   });
  // }

  handleDayClick = (day) => {
    const { setRangeAndWeek: indexSetRangeAndWeek } = this.props;

    const range = DateUtils.addDayToRange(day, this.state);
    if (indexSetRangeAndWeek) {
      // Call the hourly index setRangeAndWeek
      indexSetRangeAndWeek(range);
    } else {
      // Call the component's own setRangeAndWeek (daily index)
      this.setRangeAndWeek(range);
    }
  }

  handleWeekClick = (weekNumber, days) => {
    const { setRangeAndWeek: indexSetRangeAndWeek } = this.props;

    if (indexSetRangeAndWeek) {
      indexSetRangeAndWeek({ from: days[0], to: days[6] }, weekNumber, this.closeDateRangePicker);
    } else {
      this.setRangeAndWeek({ from: days[0], to: days[6] }, weekNumber, this.closeDateRangePicker);
    }
  }

  // generatePDF = () => {
  //   const report = new JsPDF('landscape', 'pt', 'a4');
  //   report.html(document.querySelector('#report')).then(() => {
  //     report.save('report.pdf');
  //   });
  // }

  // This is called on initial load in componentDidMount.
  getWorkOrderReportByUser = (workOrderId) => {
    const { t, reportStore } = this.props;

    // Create a promise to retrieve the work order report.
    const reportPromise = fromPromise(new Promise((resolve, reject) => {
      // Call the reportStore method to get the work order report.
      reportStore.getWorkOrderReportByUser(workOrderId, resolve, reject);
    }));

    this.setState({
      isLoading: true,
    });

    // Wait for the promise to be fulfilled or rejected.
    when(
      () => reportPromise.state !== 'pending',
      () => {
        reportPromise.case({
          rejected: (e) => {
            console.log('REJECTED: ', e); // Log the error if the promise is rejected.
            this.setState({
              isLoading: false,
            });
          },
          fulfilled: (data) => {
            // Construct the defaultInputValue for dateRangePicker
            const durationRange = data.workOrder.duration.split('-');
            const rangeStart = moment(durationRange[0], 'DD.MM.YYYY');
            const rangeStartFormatted = rangeStart.format('dd DD.MM.YYYY');
            const rangeEnd = moment(durationRange[1], 'DD.MM.YYYY');
            const rangeEndFormatted = rangeEnd.format('dd DD.MM.YYYY');
            const dayRange = `${rangeStartFormatted} - ${rangeEndFormatted}`;

            const fromWeekNumber = rangeStart.isoWeek();
            const toWeekNumber = rangeEnd.isoWeek();
            // We do not want to add the '-' if there's no second different week number
            const toWeekNumberString = toWeekNumber && toWeekNumber !== fromWeekNumber ? `-${toWeekNumber}` : '';
            const weekNumberString = `${t('resource_availability.week_prefix')}${fromWeekNumber + toWeekNumberString}`;
            const dayRangeWithWeeks = `${weekNumberString} | ${dayRange}`;

            this.setState({
              defaultInputValue: dayRangeWithWeeks,
              isLoading: false,
            });

            // Process the data
            this.setupTable(data);
          },
        });
      },
    );
  }

  handleSettingsDialogClose = () => {
    this.setState({
      settingsDialogOpen: false,
    });
  }

  toggleColumnVisibility = (columnName) => {
    const { mode, setIndexState } = this.props;
    const { mainColumns } = this.state;

    const columnIndex = mainColumns.findIndex((column) => column.name === columnName);
    const foundColumn = columnIndex !== -1 ? mainColumns[columnIndex] : null;

    const updatedColumns = [...mainColumns];

    if (foundColumn) {
      // Toggle visibility
      updatedColumns[columnIndex].options = { ...mainColumns[columnIndex].options, display: !foundColumn.options.display };
      this.setState({
        mainColumns: updatedColumns,
      }, () => {
        if (mode === 'tabReport' && setIndexState) {
          // Update the state to index so that the column visibility will remain even when changing report tabs
          setIndexState({ ...this.state, settingsDialogOpen: false });
        }
      });
    }
  }

  // getMuiTheme = () => createTheme({
  //   components: {
  //     MUIDataTableBodyCell: {
  //       styleOverrides: {
  //         root: {
  //           backgroundColor: 'red',
  //         },
  //       },
  //     },
  //   },
  // });

  handleRestoreButtonClick = () => {
    const { uiStore, handleRestoreButtonClick: indexHandleRestoreButtonClick } = this.props;
    const { defaultInputValue } = this.state;

    if (indexHandleRestoreButtonClick) {
      indexHandleRestoreButtonClick();
    } else {
      const workOrderId = uiStore.currentView.id;
      this.getWorkOrderReportByUser(workOrderId);
      this.setState({
        inputValue: defaultInputValue,
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  findVisibleColumn(columns, columnName) {
    // true or false
    return columns.some((column) => column.name === columnName && column.options?.display !== false);
  }

  // eslint-disable-next-line class-methods-use-this
  categorizeRows(data, category) {
    const groupedWorkHours = groupArray(data, category);

    // The column being grouped by is the first
    return groupedWorkHours;
  }

  hideEmptyColumnsV2(workHours) {
    const { mode, setIndexState, indexState, hideUserCol, summaryOnly } = this.props;
    const { mainColumns } = this.state; // mainColumns

    const columnsWithValues = ['user.fullName', 'time'];

    workHours.forEach((workHourEntry) => {
      const { workHour } = workHourEntry;
      // Filter mainColumns with columnsWithValues to prevent looping over keys that we know are needed
      // Quit the whole loop if the mainColumns.length is the same as columnsWithValues.length
      const filteredMainColumns = [...mainColumns].filter((col) => !columnsWithValues.includes(col.name));

      filteredMainColumns.forEach((col) => {
        const key = col.name.split('.').pop();
        const value = workHour[key];
        if (value && !columnsWithValues.includes(col.name)) {
          columnsWithValues.push(col.name);
        }
      });
    });

    // If we're hiding the user col (hybrid report with both daily and hourly, user name is already a heading), we splice the column key out
    // We do it here because it should always have a value, meaning it'll be included whether or not it is in columnsWithValues by default
    if (hideUserCol) {
      const userFullNameColIndex = columnsWithValues.findIndex((col) => col === 'user.fullName');
      if (userFullNameColIndex !== -1) {
        columnsWithValues.splice(userFullNameColIndex, 1);
      }
    }

    // Hide the time column, not needed in the summary
    if (summaryOnly) {
      const timeColIndex = columnsWithValues.findIndex((col) => col === 'time');
      if (timeColIndex !== -1) {
        columnsWithValues.splice(timeColIndex, 1);
      }
    }


    const hiddenEmptyMainColumns = [...mainColumns].map((col) => ({ ...col, options: { ...col.options, display: columnsWithValues.includes(col.name) } }));

    this.setState({
      mainColumns: hiddenEmptyMainColumns,
    }, () => {
      if (mode === 'tabReport' && setIndexState && !indexState) {
        // We save the state to the upper index component here because this should be the final setState when the component is loaded
        setIndexState({ ...this.state, settingsDialogOpen: false });
      } else if (mode === 'tabReport' && setIndexState && indexState) {
        // Use previous indexState, stored in the index
        if (indexState) {
          this.setState({
            ...indexState,
          });
        }
      }
    });
  }

  processDataForTable(data) {
    const groupedReportData = this.categorizeRows(data.workHours, 'user.fullName');
    return groupedReportData;
  }

  processDataForTableV2(data) {
    const { summaryOnly, hideUserCol } = this.props;
    // Grouping data by 'GroupingColumn'
    let groupedData = {};

    data.workHours.forEach((item) => {
      groupedData[item.user.fullName] = groupedData[item.user.fullName] || [];
      groupedData[item.user.fullName].push(item);
    });

    // Flattening the grouped data to be compatible with MUIDataTable
    const finalData = [];
    Object.keys(groupedData).sort().forEach((key) => {
      const sumWorkHour = this.calculateSumWorkHour(data.workHours, key);

      finalData.push({
        isGroup: true,
        user: {
          fullName: key,
        },
        workHour: sumWorkHour,
      });

      // Insert isGroup: false here as the first column to get the sorting to work
      if (!summaryOnly) {
        finalData.push(...groupedData[key].map((row) => { return { ...row, isGroup: false } }));
      }
    });

    // Hide the total row (all users combined) if there's only one user (hideUserCol = true)
    let newTotalSumRow;
    if (!hideUserCol) {
      const finalSum = this.calculateSumWorkHour(data.workHours);
      newTotalSumRow = {
        isGroup: true,
        user: {
          fullName: 'Yhteensä',
        },
        workHour: { ...finalSum, id: null },
      };

      finalData.push(newTotalSumRow);
    }

    return { groupedData: finalData, newTotalSumRow: newTotalSumRow };
  }

  renderDateRangePicker(label, styles, extraOverlayClassName = null) {
    const {
      from,
      to,
      inputValue,
      defaultInputValue,
      reportWorkOrder,
    } = this.state;
    const modifiers = { start: from, end: to };
    const { duration } = reportWorkOrder; // this returns in a wrong format for example. '18.05.2023-30.05.2023'

    // Splitting the duration into beginning and end dates
    const [beginningDate, endDate] = duration.split('-');

    const workOrderEndDate = moment(endDate, 'DD.MM.YYYY');
    const today = moment();
    const dayPickerMonth = workOrderEndDate.isBefore(today, 'month') ? workOrderEndDate.toDate() : today.toDate();

    // Changing the dates to year.month.day format
    const [beginningDay, beginningMonth, beginningYear] = beginningDate.split('.');
    const [endDay, endMonth, endYear] = endDate.split('.');

    // Rearranging the date parts to the desired format
    const formattedBeginningDate = new Date(
      Number(beginningYear),
      Number(beginningMonth) - 1, // Month in JavaScript Date object is 0-based
      Number(beginningDay),
    );
    const formattedEndDate = new Date(
      Number(endYear),
      Number(endMonth) - 1, // Month in JavaScript Date object is 0-based
      Number(endDay),
    );

    // Function to check if a day is within the duration
    const isDayWithinDuration = (day) => day >= formattedBeginningDate && day <= formattedEndDate;

    return (
      <DayPickerInput
        ref={this._dateRangePicker}
        value={inputValue || defaultInputValue}
        component={
          (props) => (
            <TextField
              {...props}
              {...{
                autoComplete: 'off',
                className: 'daypicker-input',
                type: 'button',
                label,
                style: { ...styles, width: '100%' },
              }}
            />
          )
        }
        inputProps={{
          ref: null, // This is needed to remove a "Function components cannot be given refs" warning
          className: '',
          style: { ...styles, width: '100%', textTransform: 'capitalize' },
          name: 'date-range',
          readOnly: true,
        }}
        dayPickerProps={{
          locale: 'fi',
          localeUtils: MomentLocaleUtils,
          className: 'employer-daypicker range-daypicker',
          showWeekNumbers: true,
          selectedDays: [from, { from, to }],
          modifiers,
          onDayClick: this.handleDayClick,
          onWeekClick: this.handleWeekClick,
          showOutsideDays: true,
          disabledDays: ((day) => !isDayWithinDuration(day)),
          month: dayPickerMonth,
          onBlur: this.closeDateRangePicker,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        overlayComponent={this.customDateRangePickerOverlay}
        keepFocus={false}
        hideOnDayClick={false}
        format="dd DD.MM.YYYY"
        classNames={{
          container: '',
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay: extraOverlayClassName ? `DayPickerInput-Overlay ${extraOverlayClassName}` : 'DayPickerInput-Overlay',
        }}
      />
    );
  }

  renderSumRowValue(row, key) {
    if (row.isGroup && row.user.fullName === 'Yhteensä') {
      const { totalSumRow } = this.state;
      return get(totalSumRow, key, null);
    }
    return get(row, key, null);
  }

  renderActionButtons(csvData, headers) {
    const { reportWorkOrder } = this.state;
    const { uiStore: { currentUser, mobileMode } } = this.props;

    const filename = `${currentUser?.accountInfo?.name}_${reportWorkOrder?.name}`;
    const normalizedFileName = normalizeFileName(filename);

    return (
      <div style={{ textAlign: 'right', marginBottom: mobileMode ? 0 : '-70px' }}>
        <button type="button" className="small MuiButtonBase-root MuiIconButton-root">
          <CSVLink
            data={csvData}
            headers={headers}
            style={{ color: 'black' }}
            filename={`${normalizedFileName}.csv`}
            className="material-icons MuiIcon-root MuiIcon-fontSizeLarge"
            // Separator needs to be explicitly set or else software will interpret the CSV differently based on things like user locale and operating system
            separator=","
          >
            download
          </CSVLink>
        </button>

        <ReactToPrint
          // NOTE: Do NOT pass an `onClick` prop to the root node of the returned component as it will be overwritten.
          trigger={() => (
            <IconButton
              aria-label="settings"
              className="small"
            >
              <Icon style={{ color: 'black' }} fontSize="large">print</Icon>
            </IconButton>
          )}
          content={() => this.componentRef}
        />

        <IconButton
          aria-label="settings"
          onClick={() => this.setState({ settingsDialogOpen: true })}
        >
          <Icon style={{ color: 'black' }} fontSize="large">settings</Icon>
        </IconButton>
      </div>
    );
  }

  renderActionButtonsNoHeader() {
    // const { mobileMode } = this.props;

    // return (
    //   <div className="hidden-print" style={{ textAlign: 'right', marginTop: mobileMode ? 0 : '-45px' }}>
    //     <IconButton
    //       aria-label="settings"
    //       onClick={() => this.setState({ settingsDialogOpen: true })}
    //     >
    //       <Icon style={{ color: 'black' }} fontSize="large">settings</Icon>
    //     </IconButton>
    //   </div>
    // );
    return null;
  }

  renderSummary(title, summary, keys) {
    const { t } = this.props;

    return (
      <table
        style={{
          fontSize: '16px',
          backgroundColor: 'white',
          boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
          marginLeft: '20px',
          // textAlign: 'left',
          padding: '5px',
          height: 'fit-content',
        }}
      >
        <thead>
          <tr style={{ textAlign: 'left' }}>
            <th>{title}</th>
            <th>Yhteensä</th>
          </tr>
        </thead>
        <tbody>
          {keys.map((key) => (
            <tr>
              <td>{t(`timelog.attributes.${key}`)}</td>
              <td>{summary[key] || 0}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderCustomTooltip(visibilityIdentifier, tooltippedNode, tooltipContent, customBottom = null, customWidth = null) {
    return (
      <div
        className="custom-tooltip-wrapper"
        key={`tooltip-${visibilityIdentifier}`}
        style={{ width: customWidth ? customWidth : null, minWidth: customWidth ? customWidth : null }}
      >
        <div
          className={
            this.state[visibilityIdentifier] ? 'custom-tooltip custom-tooltip-open' : 'custom-tooltip'
          }
          style={{
            bottom: customBottom,
          }}
        >
          {tooltipContent}
        </div>

        <div
          onMouseEnter={() => this.setState({ [visibilityIdentifier]: true })}
          onMouseLeave={() => this.setState({ [visibilityIdentifier]: false })}
          className="custom-tooltip-underline"
        >
          {/* {moment(workHour.work_hour.date).format('DD.MM.')} */}
          {tooltippedNode}
        </div>
      </div>
    );
  }

  renderStatusTextWithComment(status, employerComment) {
    const { t } = this.props;
    return (
      <div>
        <div>{t(`timelog.attributes.statuses.${status}`)}</div>
        {status === 'rejected' && (
          <>
            <br />
            <div className="tooltip-employer-comment">
              "
              {employerComment}
              "
            </div>
          </>
        )}
      </div>
    );
  }

  render() {
    const {
      reportData,
      reportWorkOrder,
      settingsDialogOpen,
      mainColumns,
      inputValue,
      defaultInputValue,
    } = this.state;

    const {
      t,
      mode,
      uiStore,
      uiStore: { currentUser },
      hideHeader,
      hourlyIndex,
    } = this.props;

    const styles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
      maxWidth: '400px',
    };

    const headers = [...mainColumns].reduce((accumulator, column) => {
      // We already have from and to columns manually inserted so we don't want the time column
      if (column.options.display && column.name !== 'time') {
        accumulator.push(
          {
            label: column.label,
            // remove the workHour prefix (used in the table as a nested accessor)
            key: column.name.split('.').pop(),
          },
        );
      }
      return accumulator;
    }, []);

    // Add the user's fullName column as the first one
    headers.unshift(
      {
        label: 'Työntekijä',
        key: 'fullName',
      },
      {
        label: 'Pvm',
        key: 'date',
      },
      {
        label: 'Alku',
        key: 'from',
      },
      {
        label: 'Loppu',
        key: 'to',
      },
    );

    let csvData = [];

    reportData?.forEach((entry) => {
      if (!entry.isGroup) {
        const timelogEntry = { fullName: entry.user.fullName, ...entry.workHour };
        if (timelogEntry.allowance) {
          timelogEntry.allowance = `${t(`timelog.attributes.${timelogEntry.allowance}_short`)}`;
        }
        if (!timelogEntry.emergencyWork) {
          timelogEntry.emergencyWork = null;
        } else {
          timelogEntry.emergencyWork = 'Kyllä';
        }

        timelogEntry.date = timelogEntry.date.format('DD.MM.YYYY');

        csvData.push(timelogEntry);
      }
    });

    csvData = sortBy([...csvData], ['fullName', 'date'], ['desc', 'desc']);
    // });

    const grid = 6; // hourlyIndex ? 6 : 3;

    const columnsWithCustomizableVisibility = mainColumns.filter((col) => col.name !== 'isGroup');

    // This was used when the daypicker was not available in the daily view. Might be useful later for editing hourly view.
    // const grid = mode !== 'tabReport' ? 3 : 6;
    return (
      <div className="action-buttons" style={{ height: '100%', width: '100%' }}>
        {mode !== 'tabReport' && (
          <DeepViewHeader
            content={reportWorkOrder?.name}
            showPreviousView={uiStore.showEmployerBilling}
          />
        )}

        {/* {!hideHeader && ( */}
        <div style={{ textAlign: 'right' }}>
          {/* {this.renderActionButtons(csvData, headers)} */}
          {hideHeader ? this.renderActionButtonsNoHeader() : this.renderActionButtons(csvData, headers)}
        </div>
        {/* )} */}

        <ReportSettingsDialog columns={columnsWithCustomizableVisibility} toggleColumnVisibility={this.toggleColumnVisibility} open={settingsDialogOpen} handleClose={this.handleSettingsDialogClose} />
        {reportWorkOrder ? (
          <>
            {!hideHeader ? (
              /* eslint-disable-next-line no-return-assign */
              <div className="printable" id="report" ref={(el) => (this.componentRef = el)}>
                <>
                  <Grid
                    container
                    spacing={2}
                    className="billing-report-header pdf-header"
                    justifyContent="space-between"
                  >
                    <Grid item xs={4}>
                      <span>{moment().format('DD/MM/YYYY')}</span>
                    </Grid>
                    <Grid item xs={4}>
                      <span style={{ textTransform: 'uppercase' }}>Tuntiraportit</span>
                      <span>{` - ${currentUser?.accountInfo?.officialName}`}</span>
                    </Grid>
                    <Grid item xs={4}>
                      {/* <span>{currentUser?.accountInfo?.accountPicture}</span> */}
                      <img style={{ maxWidth: '100%' }} src={currentUser?.accountInfo?.accountPicture} alt="company logo" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ width: '100%', height: 'fit-content', padding: '16px 0px' }} className="billing-report-header">
                    <Grid item md={grid} xs={12} className="report-purchaser">
                      <div className="header-label">Tilaaja</div>
                      <div className="header-value">{reportWorkOrder?.purchaser}</div>
                    </Grid>
                    <Grid item md={grid} xs={12} className="daypicker-input-value show-print">
                      <div className="header-label">Ajalta</div>
                      <div className="header-value capitalized">{inputValue || defaultInputValue}</div>
                    </Grid>
                    <Grid item md={grid} xs={12} className="report-duration hidden-print">
                      <div className="header-label" style={{ lineHeight: 1 }}>Ajalta</div>
                      {/* <div className="header-value">{reportWorkOrder?.duration}</div> */}
                      <div
                        className="header-value daypicker-value"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {/* t('resource_availability.date_range') */}
                        {this.renderDateRangePicker('', styles)}
                        <IconButton
                          className="restore-icon"
                          aria-label="restore"
                          style={{
                            height: '40px',
                            width: '40px',
                          }}
                          onClick={this.handleRestoreButtonClick}
                        >
                          <SettingsBackupRestoreIcon
                            className="restore-icon"
                          />
                        </IconButton>
                      </div>
                    </Grid>
                    <Grid item md={grid} xs={12} className="report-location">
                      <div className="header-label">Kohde</div>
                      <div className="header-value">{reportWorkOrder?.location}</div>
                    </Grid>
                    <Grid item md={grid} xs={12} className="report-name">
                      <div className="header-label">Nimi</div>
                      <div className="header-value">{reportWorkOrder?.name}</div>
                    </Grid>
                  </Grid>
                </>

                <div className="billing-report-table">
                  {this.renderTableV2()}
                </div>
              </div>
            ) : (
              <div className="billing-report-table">
                {this.renderTableV2()}
              </div>
            )}
          </>
        ) : (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <LoadingSpinner color="black" />
          </div>
        )}
      </div>
    );
  }
}

export default BillingReports;

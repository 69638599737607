/* eslint-disable react/destructuring-assignment */
/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import actionCable from 'actioncable';
import {
  CollapsibleList,
  SimpleListItem,
} from '@rmwc/list';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';
import {
  Card,
  CardPrimaryAction,
  CardActionButton,
  CardActions,
  CardActionButtons,
} from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import { Switch } from '@rmwc/switch';
import { ThemeProvider } from '@rmwc/theme';
import { Icon } from '@rmwc/icon';
import { IconButton as RMWCIconButton } from '@rmwc/icon-button';
import { TextField } from '@rmwc/textfield';
import { isEqual } from 'lodash';

import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';

import '@material/list/dist/mdc.list.css';
import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/switch/dist/mdc.switch.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';
import '@rmwc/list/collapsible-list.css';

import TimelogRow from './timelog-row';
import WorkOrderItem from '../calendar/WorkOrderItem';
import { buttonColors, buttonStyle } from '../calendar/EmployeeCalendar';
import moment, { combinedDateTime, longDateRange } from '../utils/moment';
import TimelogViewStore from './TimelogViewStore';
import iconDailyAllowance from '../assets/images/daily_allowance.svg';
import iconDailyAllowanceActive from '../assets/images/daily_allowance_active.svg';
import iconExtras from '../assets/images/extra_allowance.svg';
import iconExtrasActive from '../assets/images/extra_allowance_active.svg';
import Attachments from './TimelogAttachments';
import TimelogRoute from './timelog-route';
import TimelogTripDialog from './timelog-trip-dialog.js'

const columnWidths = { työaika: '30%', päiväraha: '66%', tuotteet: '100%' };
const defaultHeaderStyle = { width: '16.5%', color: 'var(--mdc-theme-primary)' };

const totalByIntAttr = (acc, value) => Math.round((acc + (Number.parseInt(value, 10) || 0)) * 100) / 100;
const totalByAttr = (acc, value) => Math.round((acc + (Number.parseFloat(value) || 0)) * 100) / 100;
const totalByBoolAttr = (acc, value) => acc + (value ? 1 : 0);

const getSummary = (workHourType, workHours, accountId) => {
  // const { uiStore: { currentUser } } = this.props;
  const summary = {
    driveTime: 0.00,
    emergencyWork: 0.00,
    eveningShift: 0.00,
    hasUnacceptedHours: false,
    kms: 0,
    kmsPerson: 0,
    kmsTrailer: 0,
    kmsService: 0,
    kmsKit: 0,
    kmsHeavyLoad: 0,
    nightShift: 0.00,
    sundayWork: 0.00,
    time100: 0.00,
    time50: 0.00,
    timeNormal: 0.00,
    travelTime: 0.00,
    weeklyRest: 0.00,
    conditionCompensation: 0.00,
    conditionCompensationTwo: 0.00,
    conditionCompensationThree: 0.00,
    conditionCompensationFour: 0.00,
    miningCompensation: 0.00,
    midweekHolidayCompensation: 0.00,
  };

  if (workHourType === 'hourly') {
    // In this case workHours contains an array of objects with keys "date", "hours" and "dailyData"
    // One per date, because workers input multiple work hours per day (by work number)
    workHours.forEach((workHour) => {
      workHour.workTaskEntries.forEach((item) => {
        summary.timeNormal = totalByAttr(summary.timeNormal, item.timeNormal);
        summary.time50 = totalByAttr(summary.time50, item.time50);
        summary.time100 = totalByAttr(summary.time100, item.time100);
      });
      // if (workHoursPerDay.dailyData) {
      summary.travelTime = totalByAttr(summary.travelTime, workHour.travelTime);
      // TODO: Hardcoded accounts 1, 10 and 3 (10 and 3 being test accounts)
      if (accountId === 1 || accountId === 10 || accountId === 3) {
        summary.driveTime = totalByAttr(summary.driveTime, workHour.driveTime);
        summary.driveTime = totalByAttr(summary.driveTime, workHour.driveTime50);
        summary.driveTime = totalByAttr(summary.driveTime, workHour.driveTime100);
      } else {
        summary.driveTime = totalByAttr(summary.driveTime, workHour.driveTime);
      }
      summary.dailyAllowance = totalByAttr(summary.dailyAllowance, workHour.dailyAllowance);
      summary.kms = totalByIntAttr(summary.kms, workHour.kms);
      summary.kmsPerson = totalByIntAttr(summary.kmsPerson, workHour.kmsPerson);
      summary.kmsTrailer = totalByIntAttr(summary.kmsTrailer, workHour.kmsTrailer);
      summary.kmsService = totalByIntAttr(summary.kmsService, workHour.kmsService);
      summary.kmsHeavyLoad = totalByIntAttr(summary.kmsHeavyLoad, workHour.kmsHeavyLoad);
      summary.kmsKit = totalByIntAttr(summary.kmsKit, workHour.kmsKit);

      summary.eveningShift = totalByAttr(summary.eveningShift, workHour.eveningShift);
      summary.nightShift = totalByAttr(summary.nightShift, workHour.nightShift);
      summary.sundayWork = totalByAttr(summary.sundayWork, workHour.sundayWork);
      summary.weeklyRest = totalByAttr(summary.weeklyRest, workHour.weeklyRest);
      summary.emergencyWork = totalByBoolAttr(summary.emergencyWork, workHour.emergencyWork);
      summary.conditionCompensation = totalByAttr(summary.conditionCompensation, workHour.conditionCompensation);
      summary.conditionCompensationTwo = totalByAttr(summary.conditionCompensationTwo, workHour.conditionCompensationTwo);
      summary.conditionCompensationThree = totalByAttr(summary.conditionCompensationThree, workHour.conditionCompensationThree);
      summary.conditionCompensationFour = totalByAttr(summary.conditionCompensationFour, workHour.conditionCompensationFour);
      summary.miningCompensation = totalByAttr(summary.miningCompensation, workHour.miningCompensation);
      summary.midweekHolidayCompensation = totalByAttr(summary.midweekHolidayCompensation, workHour.midweekHolidayCompensation);

      if (workHour.isDraft()) {
        summary.hasUnacceptedHours = true;
      }
      if (workHour.updatedByEmployer) {
        summary.hasUpdatedByEmployer = true;
      }
      // }
    });
  } else {
    workHours.forEach(
      (item) => {
        summary.timeNormal = totalByAttr(summary.timeNormal, item.timeNormal);
        summary.time50 = totalByAttr(summary.time50, item.time50);
        summary.time100 = totalByAttr(summary.time100, item.time100);

        summary.travelTime = totalByAttr(summary.travelTime, item.travelTime);
        // TODO: Hardcoded accounts 1, 10 and 3 (10 and 3 being test accounts)
        if (accountId === 1 || accountId === 10 || accountId === 3) {
          summary.driveTime = totalByAttr(summary.driveTime, item.driveTime);
          summary.driveTime = totalByAttr(summary.driveTime, item.driveTime50);
          summary.driveTime = totalByAttr(summary.driveTime, item.driveTime100);
        } else {
          summary.driveTime = totalByAttr(summary.driveTime, item.driveTime);
        }
        summary.dailyAllowance = totalByAttr(summary.dailyAllowance, item.dailyAllowance);
        summary.kms = totalByIntAttr(summary.kms, item.kms);
        summary.kmsPerson = totalByIntAttr(summary.kmsPerson, item.kmsPerson);
        summary.kmsTrailer = totalByIntAttr(summary.kmsTrailer, item.kmsTrailer);
        summary.kmsService = totalByIntAttr(summary.kmsService, item.kmsService);
        summary.kmsHeavyLoad = totalByIntAttr(summary.kmsHeavyLoad, item.kmsHeavyLoad);
        summary.kmsKit = totalByIntAttr(summary.kmsKit, item.kmsKit);

        summary.eveningShift = totalByAttr(summary.eveningShift, item.eveningShift);
        summary.nightShift = totalByAttr(summary.nightShift, item.nightShift);
        summary.sundayWork = totalByAttr(summary.sundayWork, item.sundayWork);
        summary.weeklyRest = totalByAttr(summary.weeklyRest, item.weeklyRest);
        summary.emergencyWork = totalByBoolAttr(summary.emergencyWork, item.emergencyWork);
        summary.conditionCompensation = totalByAttr(summary.conditionCompensation, item.conditionCompensation);
        summary.conditionCompensationTwo = totalByAttr(summary.conditionCompensationTwo, item.conditionCompensationTwo);
        summary.conditionCompensationThree = totalByAttr(summary.conditionCompensationThree, item.conditionCompensationThree);
        summary.conditionCompensationFour = totalByAttr(summary.conditionCompensationFour, item.conditionCompensationFour);
        summary.miningCompensation = totalByAttr(summary.miningCompensation, item.miningCompensation);
        summary.midweekHolidayCompensation = totalByAttr(summary.midweekHolidayCompensation, item.midweekHolidayCompensation);

        if (item.isDraft()) {
          summary.hasUnacceptedHours = true;
        }
        if (item.updatedByEmployer) {
          summary.hasUpdatedByEmployer = true;
        }
      },
    );
  }

  return summary;
};

@inject('uiStore', 'timelogStore', 't', 'loginStore', 'workOrderTripStore', 'productStore', 'globalSnackbarStore')
@observer
class TimelogView extends Component {
  cableApp = {};

  constructor(props) {
    super(props);

    this.deleteHourlyTimelog = this.deleteHourlyTimelog.bind(this);
    this.updateWorkOrderTripState = this.updateWorkOrderTripState.bind(this);
    this.toggleTripDialog = this.toggleTripDialog.bind(this);
    // this.overwriteTripRoutes = this.overwriteTripRoutes.bind(this);

    // TODO: In order to add compatibility with
    // const foundWorkOrderTripForThisWorkOrder = props.workOrder.workOrderTrips.find((woTrip) => woTrip.startTrip[0]?.uiWorkOrderId === props.workOrder.id || woTrip?.endTrip[0]?.uiWorkOrderId === props.workOrder.id);
    // console.log('FOUND WORK ORDER TRIP: ', foundWorkOrderTripForThisWorkOrder);

    //const foundOnGoingTrip = props.workOrder.workOrderTrips.find(woTrip => woTrip.endTrip.length == 0 || woTrip.endTrip[0]?.toTime == null);
    //const foundEndedTrips = props.workOrder.workOrderTrips.filter(woTrip => woTrip.endTrip.length > 0 && woTrip.endTrip[0]?.toTime != null);
    // const foundOnGoingTrip = props.workOrderTrips.find(woTrip => woTrip.endTrip.length > 0 || woTrip.endTrip?.[0]?.toTime == null || woTrip.endTrip?.[0]?.toDate == null);
    const foundOnGoingTrip = props.workOrderTrips.find(woTrip => !woTrip.endTrip || (woTrip.endTrip && woTrip.endTrip?.toTime == null || woTrip.endTrip?.toDate == null));
    const foundEndedTrips = props.workOrderTrips.filter(woTrip => woTrip.endTrip && woTrip.endTrip?.toTime != null);

    this.state = {
      activeTabById: {},
      dialogOpen: false,
      // workOrderTrip: foundWorkOrderTripForThisWorkOrder || [],
      routeDialogOpen: false,
      tripDialogOpen: false,
      cloneTripDialogOpen: false,
      routeItem: '',
      tripItem: '',
      currentKmsType: null,
      onGoingTrip: foundOnGoingTrip || null,
      endedTrips: foundEndedTrips || [],
      maxEndDate: null,
      tripDisabledDialogOpen: false,
      tripDisabledDialogOverlappingTrips: [],
      rowActionsOpen: null,
      currentCopyOptions: [],
      visibleCopyOptionIndex: 0,
      copiedRowId: null,
      // previousCopyOption: {},
    };
    this.store = new TimelogViewStore();

    if (props.initialTabIndex) {
      this.state.activeTabById[props.workOrder.id] = props.initialTabIndex;
    }
    // const activeTab = initialTabIndex ? initialTabIndex : (activeTabById[workOrder.id] || 0);
  }

  getHeader = (columns, tab = null) => columns.map((text) => this.getHeaderTitle(
    text,
    text,
    {
      borderBottomWidth: '1px',
      borderTopWidth: 0,
      fontSize: '10px',
      lineHeight: '1rem',
      width: columnWidths[text] ? columnWidths[text] : '16.5%',
      // width: '17.5%',
    },
    tab,
  ));

  getFirstColumn = (text, ctx, tab) => {
    const { uiStore: { currentUser: { accountId } } } = this.props;
    return (
      <div
        className={`pk-multiline-header sticky-left ${((tab === 1 && (accountId === 43 || accountId === 1 || accountId === 58)) || tab === 3) ? "mobile-column" : ""}`}
        style={{
          // color: 'var(--mdc-theme-primary)',
          fontSize: '10px',
          lineHeight: '1rem',
          padding: 0,
          textAlign: 'center',
          textTransform: 'uppercase',
          ...ctx,
        }}
      >
        {text}
      </div>
    );
  };

  // componentDidUpdate(prevProps) {
  //   console.log('IS EQUAL??', isEqual(prevProps.workOrder.workHours, this.props.WorkOrder.workHours));
  // }

  getHeaderTitle = (text, name = text, styles = {}, tab = null) => {
    const { uiStore: { currentUser: { accountId } } } = this.props;
    return (
      <div
        className={
          `pk-multiline-header
          ${((tab === 1 && (accountId === 43 || accountId === 1 || accountId === 58)) || tab === 3) ? 'mobile-column' : ''}
          ${text === 'päiväraha' ? 'allowance-column' : ''}
          ${text === 'työmatka' ? 'work-order-trip-column' : ''}`
        }
        key={`tbheader-${name}`}
        style={{
          borderBottomWidth: 0,
          // color: 'var(--mdc-theme-primary)',
          fontSize: '1rem',
          lineHeight: '2rem',
          padding: (accountId === 1 || accountId === 58) ? '0 1px' : 0,
          textAlign: 'center',
          textTransform: 'uppercase',
          ...styles,
        }}
      >
        {text}
      </div>
    );
  };

  updateWorkOrderTripState(newWorkOrderTrip) {
    const { workOrder, timelogStore } = this.props;
    this.setState({
      workOrderTrip: newWorkOrderTrip,
    });
    timelogStore.updateWorkOrderTripProp(newWorkOrderTrip, workOrder.id);
  }

  toggleRouteDialog = (item = null, kmsType, workOrder) => {
    const { openDialogCallback } = this.props;
    const { routeItem, routeDialogOpen } = this.state;

    if (routeItem && routeDialogOpen) {
      this.setState({
        routeItem: '',
        routeDialogOpen: false,
        currentKmsType: null,
        currentWorkOrder: null,
      })
    }
    else {
      this.setState({
        routeItem: item,
        routeDialogOpen: true,
        currentKmsType: kmsType,
        currentWorkOrder: workOrder,
      })
    }

    if (openDialogCallback) {
      openDialogCallback();
    }
  }

  // Compare prev and current's toDate & toTime to see which one is chronologically greater
  greaterEndDateTimeReducer(prev, current) {
    const currentEndDate = current.endTrip.toDate;
    const currentEndTime = current.endTrip.toTime;
    const previousEndDate = prev.endTrip.toDate;
    const previousEndTime = prev.endTrip.toTime;

    if (previousEndDate > currentEndDate || (previousEndDate == currentEndDate && previousEndTime > currentEndTime)) {
      // console.log(`${previousEndDate?.format('YYYY-MM-DD')} ${previousEndTime} is greater than ${currentEndDate?.format('YYYY-MM-DD')} ${currentEndTime}`);
      return prev;
    } else {
      // console.log(`${currentEndDate?.format('YYYY-MM-DD')} ${currentEndTime} is greater than ${previousEndDate?.format('YYYY-MM-DD')} ${previousEndTime}`);
      return current;
    }
  }

  // Compare prev and current's date & time to see which one is chronologically smaller
  smallerStartDateTimeReducer(prev, current) {
    const currentStartDate = current.startTrip.date;
    const currentStartTime = current.startTrip.time;
    const previousStartDate = prev.startTrip.date;
    const previousStartTime = prev.startTrip.time;

    if (previousStartDate < currentStartDate || (previousStartDate == currentStartDate && previousStartTime < currentStartTime)) {
      // console.log(`${previousStartDate?.format('YYYY-MM-DD')} ${previousStartTime} is smaller than ${currentStartDate?.format('YYYY-MM-DD')} ${currentStartTime}`);
      return prev;
    } else {
      // console.log(`${currentStartDate?.format('YYYY-MM-DD')} ${currentStartTime} is smaller than ${previousStartDate?.format('YYYY-MM-DD')} ${previousStartTime}`);
      return current;
    }
  }

  // We want to check for a trip that starts on the previous day and has a duration of below 24 hours
  // This is used to make life easier for users that have repeating daily trips (might be during the day or the night)
  findClonableTrip(defaultTripDate, endedTrips) {
    const { workOrder } = this.props;
    // Note: this is efficient, essentially we loop through all trips every time the user wants to create a new trip
    // We should reduce the number of trips that need to be looped through. Load historical/archived trips of old work orders only as needed
    const oneDayTrip = endedTrips.find((trip) => {
      const startDateTime = combinedDateTime(trip.startTrip?.date, trip.startTrip?.time);
      const endDateTime = combinedDateTime(trip.endTrip?.toDate, trip.endTrip?.toTime);

      if (trip.workOrders && trip.workOrders.length !== 0 && !trip.workOrders.map((wo) => wo.id).includes(workOrder.id)) {
        // Trip "belongs" to a work order different to this one
        return false;
      }

      if (!startDateTime || !endDateTime) {
        // Continue to the next trip in find()
        // Should be redundant since we're looping through endedTrips but "safety through redundancy"
        return false;
      }

      const hourDiff = endDateTime.diff(startDateTime, 'hours', true);
      if (hourDiff > 24) {
        // Continue to the next trip in find()
        return false;
      }

      // We check if the trip's start date is on the previous day
      const potentialDefaultTripDate = startDateTime.add(1, 'days').format('DD.MM.YYYY');
      const defaultTripDateFormatted = defaultTripDate.format('DD.MM.YYYY');

      if (hourDiff < 24 && potentialDefaultTripDate === defaultTripDateFormatted) {
        return trip;
      }
    })
    if (oneDayTrip) {
      return oneDayTrip;
    }
    return null;
  }

  toggleTripDialog(item = null, defaultTripDate = null, overlappingTrips = [], endedTrips = []) {
    const { disabled } = this.props;
    const { tripDialogOpen } = this.state;

    // The timelog view is disabled AND
    // Dialog isn't currently open AND
    // Item doesn't exist (trying to create a new trip)
    if (disabled && !tripDialogOpen && !item) {
      return;
    }

    if (item === null && !tripDialogOpen) {
      const clonableTrip = this.findClonableTrip(defaultTripDate, endedTrips);
      if (clonableTrip) {
        this.setState({
          cloneTripDialogOpen: true,
          clonableTrip,
          // We store the setOpenTrip params temporarily so that we can "continue" where we left off from the clone dialog
          tripDialogParams: {
            item,
            defaultTripDate,
            overlappingTrips,
            endedTrips,
          }
        });
        return;
      }
    }

    this.openOrCloseTrip(item, defaultTripDate, overlappingTrips, endedTrips);
  }

  openTripDisabledDialog = (otherWoOverlappingTrips, allOverlappingTrips) => {
    this.setState({
      tripDisabledDialogOpen: true,
      tripDisabledDialogOverlappingTrips: otherWoOverlappingTrips,
      allOverlappingTrips: allOverlappingTrips
    });
  }

  openOrCloseTrip(item = null, defaultTripDate = null, overlappingTrips = [], endedTrips = [], tripToBeCloned = null) {
    const { workOrderTrips, openDialogCallback } = this.props;
    const { tripDialogOpen } = this.state;

    const startDate = item?.startTrip.date || defaultTripDate;

    // TODO: We definitely shouldn't be looping all the trips here
    // The amount of trips needs to be limited so that this system is scalable

    // Get trips with end dates smaller than current trip's start date, these are used to get the minimum allowed start date + time
    const smallerEndDates = workOrderTrips.filter((trip) => trip.endTrip.toDate <= startDate && trip.id !== item?.id);
    // Get trips with start dates greater than current trip's start date, these are used to get the maximum allowed end date + time
    const greaterStartDates = workOrderTrips.filter((trip) => trip.startTrip.date >= startDate && trip.id !== item?.id);

    // NOTE: This system is inherently flawed in terms of multiple trips per single day
    // It assumes that each trip only has two datetimes, min and max, which limit it
    // But with multiple single day trips, we do not know where the new trip will be created within the day, so there's more than 2 limiting datetimes
    // E.g. click on the day 1.2. with an existing trip 8:00-12:00. The new trip could be e.g. 06:00-08:00 or 12:00-14:00
    // The temporary solution is to always use the next day's trip as the maxEndDate + maxEndTime

    // If array has elements (to prevent TypeError: Reduce of empty array with no initial value), get the smallest of the end dates
    const greatestSmallerEndDate = smallerEndDates.length > 0 && smallerEndDates.reduce((prev, current) => this.greaterEndDateTimeReducer(prev, current));
    const smallestGreaterStartDate = greaterStartDates.length > 0 && greaterStartDates.reduce((prev, current) => this.smallerStartDateTimeReducer(prev, current));

    // We want to remove the opened item from the overlapping trips so that it doesn't overlap with its own old values
    let overlappingTripsWithoutItem = null;
    if (item) {
      overlappingTripsWithoutItem = overlappingTrips.filter((overlappingTrip) => overlappingTrip.id !== item.id);
    }

    if (tripDialogOpen) {
      // Closing dialog
      this.setState({
        tripItem: '',
        tripDialogOpen: false,
        defaultTripDate: '',
        maxStartDate: '',
        maxStartTime: '',
        maxEndDate: '',
        maxEndTime: '',
        overlappingTrips: [],
        tripToBeCloned: null,
      });
    } else {
      this.setState({
        tripItem: item,
        tripDialogOpen: true,
        defaultTripDate: defaultTripDate,
        // maxStartDate: maxStartDate,
        // maxStartTime: maxStartTime,
        // maxEndDate: maxEndDate,
        // maxEndTime: maxEndTime,
        maxStartDate: greatestSmallerEndDate.endTrip?.toDate,
        maxStartTime: greatestSmallerEndDate.endTrip?.toTime,
        maxEndDate: smallestGreaterStartDate.startTrip?.date,
        maxEndTime: smallestGreaterStartDate.startTrip?.time,
        overlappingTrips: overlappingTripsWithoutItem || overlappingTrips,
        tripToBeCloned,
      });
    }

    if (openDialogCallback) {
      openDialogCallback();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getHeader2(columns) {
    let width = '17.5%';
    columns.map((text) => {
      if (text === 'työaika') {
        width = '30%';
      } else if (text === 'päiväraha') {
        width = '82.5%';
      }
      return this.getHeaderTitle(
        text,
        text,
        {
          borderBottomWidth: '1px',
          borderTopWidth: 0,
          fontSize: '10px',
          lineHeight: '1rem',
          // width: text === 'työaika' ? '30%' : '17.5%',
          // width: '17.5%',
          width,
        },
      );
    });
  }

  // TODO: Make the data table actually dynamic
  conditionOrSundayWorkHeader = (summary, width, color, tab) => {
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    if (accountId === 7 || accountId === 10) {
      return this.getHeaderTitle(summary.conditionCompensation, 'total3-5', { width, color }, tab);
    }
    // return this.getHeaderTitle(summary.emergencyWork, 'total3-5', { width, color });
    // return this.getHeaderTitle(summary.sundayWork, 'total3-1', defaultHeaderStyle)
    return this.getHeaderTitle(summary.sundayWork, 'total3-1', { width: width, color: 'var(--mdc-theme-primary)' }, tab)
  };

  conditionOrEmergencyHeader = (summary, width, color, tab) => {
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    if (accountId === 2) {
      return this.getHeaderTitle(summary.conditionCompensation, 'total3-5', { width, color }, tab);
    }
    return this.getHeaderTitle(summary.emergencyWork, 'total3-5', { width, color }, tab);
  };

  // TODO: Make the data table actually dynamic
  kmsTrailerOrServiceHeader = (summary, width, color, tab = null) => {
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    if (accountId === 2 || accountId === 42 || accountId === 6) {
      return this.getHeaderTitle(summary.kmsService, 'total2-6', { width, color }, tab);
    }
    return this.getHeaderTitle(summary.kmsTrailer, 'total2-6', { width, color }, tab);
  };

  // TODO: Hardcoded to a specific customer for now, but in the future should be configured per work order / account
  getTabOneHeaders = () => {
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    if (accountId === 1) {
      return ['matka\u00ADtunnit', 'kulj.\ntunnit', 'km oma\nauto', 'km +\n1 hlö', 'km huolto\u00ADauto'];
    }
    if (accountId === 2) {
      return ['työajan\nulkop.', 'työ\u00ADajalla', 'km oma\nauto', 'km +\n1 hlö', 'km huolto\u00ADauto'];
    }
    // if (accountId === 6) {
    //   return ['matka h'];
    // }
    // Hardcoded to accounts 7, 10 and 3 (indirectly related to work hour trips/kms routes)
    if (accountId === 7) {
      return ['matka h', 'km oma\nauto', 'km huolto\u00ADauto'];
    }
    if (accountId === 10 || accountId === 3 || accountId === 159) {
      return ['matka\u00ADtunnit', 'km oma\nauto', 'km +\npakki', 'km huolto\u00ADauto', 'km +\n1 hlö'];
    }
    if (accountId === 41) {
      return ['matka h', 'km oma\nauto', 'km +\n1 hlö', 'km +\nperäkär.'];
    }
    if (accountId === 49) {
      return ['matka h', 'km oma\nauto', 'km +\n1 hlö', 'km +\nperäkär.', 'km huolto\u00ADauto'];
    }
    if (accountId === 42 || accountId === 6) {
      return ['matka\u00ADtunnit', 'km oma\nauto', 'km +\n1 hlö', 'km huolto\u00ADauto'];
    }
    if (accountId === 43) {
      return ['työajan\nulkop.', 'työ\u00ADajalla', 'km oma\nauto', 'km +\n1 hlö', 'km +\nperäkär.', 'km iso\nkuorma'];
    }
    if (accountId === 58) {
      return ['matka h', 'km huolto\u00ADauto', 'kulj.\ntunnit', 'km oma\nauto', '+ km 1 hlö', '+ km iso\nkuorma'];
    }
    if (accountId === 59) {
      return ['km työ\u00ADauto', 'km oma\nauto', 'matka\u00ADtunnit'];
    }
    return ['matka\u00ADtunnit', 'km huolto\u00ADauto', 'km oma\nauto', 'km +\n1 hlö'];
  };

  getTabTwoHeaders = () => {
    const { uiStore: { currentUser: { accountInfo: { workOrderTripsEnabled } } } } = this.props;

    if (workOrderTripsEnabled) {
      return ['työmatka', 'päiväraha'];
    }
    return ['päiväraha'];
  }

  // TODO: Hardcoded to a specific customer for now, but in the future should be configured per work order / account
  getTabThreeHeaders = () => {
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    if (accountId === 2) {
      return ['su\nkorvaus', 'vkolepo\u00ADkorvaus', 'ilta\u00ADlisä', 'yö\u00ADlisä', 'olosuhde\u00ADlisä', 'arkipyhä\u00ADkorv.'];
    }
    if (accountId === 7 || accountId === 10) {
      return ['su\nkorvaus', 'olosuhde\u00ADlisä', 'vkolepo\u00ADkorvaus', 'ilta\u00ADlisä', 'yö\u00ADlisä', 'hälytys\u00ADkorvaus', 'kaivos\u00ADlisä', 'arkipyhä\u00ADkorv.'];
    }
    if (accountId === 43) {
      return ['su\nkorvaus', 'vkolepo\u00ADkorvaus', 'hälytys\u00ADraha', 'arkipyhä\u00ADkorv.'];
    }
    if (accountId === 49 || accountId === 159 || accountId === 3 || accountId === 192 || accountId === 93) {
      return ['su\nkorvaus', 'olosuhde\u00ADlisä', 'vkolepo\u00ADkorvaus', 'ilta\u00ADlisä', 'yö\u00ADlisä', 'hälytys\u00ADraha', 'arkipyhä\u00ADkorv.'];
    }
    if (accountId === 58) {
      return ['su\nkorvaus', 'olo 1', 'olo 2', 'olo 3', 'olo 4', 'vkolepo\u00ADkorvaus', 'ilta\u00ADlisä', 'yö\u00ADlisä', 'hälytys\u00ADraha', 'arkipyhä\u00ADkorv.'];
    }
    return ['su\nkorvaus', 'vkolepo\u00ADkorvaus', 'ilta\u00ADlisä', 'yö\u00ADlisä', 'hälytys\u00ADraha', 'arkipyhä\u00ADkorv.'];
  };

  deleteHourlyTimelog(timelog, hourlyTimelogs) {
    const { timelogStore } = this.props;
    // needs the hourly-timelog prop + the workOrder to be deleted
    timelogStore.deleteHourlyTimelogEntry(timelog, hourlyTimelogs);
  }

  renderAcceptance(workOrder) {
    const {
      props: {
        t, timelogStore, uiStore, to, from,
      }, store,
    } = this;
    const buttonStyles = { ...buttonStyle, ...buttonColors.yellow };

    let buttons = (
      <CardActionButtons
        style={{
          flexDirection: 'column',
        }}
      >
        <CardActionButton
          {...{
            onClick: () => {
              timelogStore.accept(workOrder, uiStore.currentUser, from, to);
              store.setShowDraftsOnly(false);
            },
            outlined: true,
            style: buttonStyles,
          }}
        >
          {t('timelog.acceptance.accept')}
        </CardActionButton>
      </CardActionButtons>
    );

    return (
      <Card>
        <Typography {...{
          use: 'body1',
          tag: 'p',
          style: {
            margin: '0 1rem 0 1rem',
            padding: '0 0 0 1rem',
          },
        }}
        >
          {t('timelog.acceptance.title')}
        </Typography>
        <CardPrimaryAction
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '0 1rem 0 1.5rem',
          }}
        >
          <CardActions
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <ThemeProvider
              options={{
                primaryOnBackground: 'white',
                secondary: buttonColors.yellow.color,
                textPrimaryOnBackground: 'var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important',
              }}
            >
              <Switch
                className="pk-switch"
                checked={store.showDraftsOnly}
                onChange={(evt) => store.setShowDraftsOnly(evt.currentTarget.checked)}
                label={t('timelog.acceptance.showDraftsOnly')}
                use="text-hint-on-dark"
              />
            </ThemeProvider>

          </CardActions>
          <CardActions>{buttons}</CardActions>
        </CardPrimaryAction>
      </Card>
    );
  }

  renderAcceptanceEmployer() {
    const {
      t,
      timelogStore,
      uiStore,
      to,
      from,
      workOrder,
      resetUpdatedByEmployer,
      employerContextTripId,
      relayUpdatedWorkHours,
    } = this.props;
    const { store, store: { showEmployerUpdatedOnly } } = this;
    const buttonStyles = { ...buttonStyle, ...buttonColors.yellow };

    return (
      <Card>
        <CardPrimaryAction
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '0 1rem 0 1.5rem',
          }}
        >
          <CardActions
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <ThemeProvider
              options={{
                primaryOnBackground: 'white',
                secondary: buttonColors.yellow.color,
                textPrimaryOnBackground: 'var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important',
              }}
            >
              <Switch
                className="pk-switch"
                checked={showEmployerUpdatedOnly}
                onChange={(evt) => store.setShowEmployerUpdatedOnly(evt.currentTarget.checked)}
                label={t('timelog.acceptance.showDraftsOnly')}
                use="text-hint-on-dark"
              />
            </ThemeProvider>
          </CardActions>
        </CardPrimaryAction>
      </Card>
    );
  }

  renderCloneTripDialog() {
    const { cloneTripDialogOpen, clonableTrip, tripDialogParams } = this.state;
    let tripDateTimeString;
    if (clonableTrip) {
      tripDateTimeString = longDateRange(clonableTrip.startTrip.date, clonableTrip.startTrip.time, clonableTrip.endTrip.toDate, clonableTrip.endTrip.toTime);
    }

    return (
      <Dialog
        className="mui-dialog-background"
        fullScreen={false}
        open={cloneTripDialogOpen}
        onClose={() => this.setState({ cloneTripDialogOpen: false })}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            Haluatko kopioida edellisen päivän matkan?
          </DialogContentText>
          <DialogContentText style={{ color: 'darkgray', fontSize: '14px', textTransform: 'capitalize' }}>
            {tripDateTimeString}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between' }}
        >
          <Button
            onClick={() => this.setState({
              cloneTripDialogOpen: false,
            }, () => this.openOrCloseTrip(null, tripDialogParams.defaultTripDate, tripDialogParams.overlappingTrips, tripDialogParams.endedTrips, clonableTrip))}
            className="employee-accept-button"
          >
            Kyllä
          </Button>
          <Button
            onClick={() => this.setState({
              cloneTripDialogOpen: false,
              clonableTrip: null,
            }, () => this.openOrCloseTrip(tripDialogParams.item, tripDialogParams.defaultTripDate, tripDialogParams.overlappingTrips, tripDialogParams.endedTrips))}
            className="employee-reject-button"
          >
            Ei
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderTripDisabledDialog() {
    const { uiStore: { mobileMode, currentUser: { workOrders } }, workOrderTripStore, workOrder: { id: workOrderId, name: workOrderName } } = this.props;
    const { tripDisabledDialogOpen, tripDisabledDialogOverlappingTrips, allOverlappingTrips } = this.state;

    const trips = tripDisabledDialogOverlappingTrips.map((trip) => ({
      id: trip.id,
      date: longDateRange(trip.startTrip.date, trip.startTrip.time, trip.endTrip.toDate, trip.endTrip.toTime),
      workOrderNames: trip.workOrders.map((wo) => wo.name), // trip.workOrders.map((tripWorkOrder) => workOrders.find((workOrder) => workOrder.id === tripWorkOrderId)?.name),
      originalObject: trip,
    }));

    return (
      <Dialog
        className="mui-dialog-background"
        fullScreen={false}
        open={tripDisabledDialogOpen}
        // This onClose is triggered when clicking outside the dialog to close it
        onClose={() => this.setState({
          tripDisabledDialogOpen: false,
          tripDisabledDialogOverlappingTrips: [],
          allOverlappingTrips: [],
        })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="responsive-dialog-title-button-only">
          <IconButton
            aria-label="close"
            onClick={() => this.setState({
              tripDisabledDialogOpen: false,
              tripDisabledDialogOverlappingTrips: [],
              allOverlappingTrips: [],
            })}
          >
            <Icon icon="close" role="button" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Valitulla päivällä on jo matka toisella keikalla:
          </DialogContentText>
          {trips.map((trip) => (
            <div
              key={trip.id} style={{ marginTop: '10px' }}
            >
              <div>
                <div style={{ fontSize: '14px' }}>{trip.date}</div>
                {trip.workOrderNames.map((workOrderName) => (
                  <div
                    key={workOrderName}
                    style={{ marginLeft: '20px', fontSize: '12px', color: 'darkgray' }}
                  >
                    {workOrderName}
                  </div>
                ))}
              </div>
              {trips.length > 1 && (
                <RMWCIconButton
                  icon="edit"
                  onClick={() => {
                    // const endedTrips = allOverlappingTrips.filter((woTrip) => woTrip.endTrip.length > 0 && woTrip.endTrip[0]?.toTime != null);
                    this.setState({
                      tripDisabledDialogOpen: false,
                      tripDisabledDialogOverlappingTrips: [],
                      allOverlappingTrips: [],
                    }, () => {
                      this.openOrCloseTrip(trip.originalObject, null, allOverlappingTrips, null, null);
                    })
                  }}
                  style={{ color: '#c7c9cc', paddingTop: '0', paddingBottom: '15px', height: 'fit-content' }}
                />
              )}
              {/* <div>
                <IconButton>
                  <Icon icon="edit" role="button" style={{ color: 'var(--mdc-theme-primary)' }} />
                </IconButton>
              </div> */}
            </div>
          ))}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: trips.length === 1 && 'space-between' }}
          className={mobileMode ? 'responsive-dialog-stacked-action-buttons' : ''}
        >
          {trips.length === 1 && (
            <>
              <Button
                className="employee-accept-button"
                onClick={() => {
                  // const endedTrips = allOverlappingTrips.filter((woTrip) => woTrip.endTrip.length > 0 && woTrip.endTrip[0]?.toTime != null);
                  this.setState({
                    tripDisabledDialogOpen: false,
                    tripDisabledDialogOverlappingTrips: [],
                    allOverlappingTrips: [],
                  }, () => {
                    this.openOrCloseTrip(trips[0].originalObject, null, allOverlappingTrips, null, null);
                  });
                }}
              >
                Päätä matka
              </Button>

              <Button
                onClick={() => {
                  // const endedTrips = allOverlappingTrips.filter((woTrip) => woTrip.endTrip.length > 0 && woTrip.endTrip[0]?.toTime != null);
                  this.setState({
                    tripDisabledDialogOpen: false,
                    tripDisabledDialogOverlappingTrips: [],
                    allOverlappingTrips: [],
                  }, () => {
                    // We're saving the whole trip here, we could also just use trip id + work order id to attach the work order to the trip
                    workOrderTripStore.save(trips[0].originalObject, workOrderId, workOrderName);
                  });
                }}
                className="employee-accept-button"
              >
                Jatka matkaa keikalla
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const {
      props: {
        workOrder, disabled, locked, to, from, periodId, uiStore, t, timelogStore, workOrderTrips,
        timelogStore: { attachments },
        relayUpdatedWorkHours,
      },
      state: { activeTabById }, store,
    } = this;

    // Move this outside of render? Are the work days ever updated?
    const flatWorkDays = new Set();
    workOrder.workDays.forEach((workDay) => {
      // CalendarEntries instances
      flatWorkDays.add(workDay.from.format('DD.MM.YY'));
      flatWorkDays.add(workDay.to.format('DD.MM.YY'));
    });

    const {
      routeDialogOpen, tripDialogOpen, routeItem, tripItem, currentKmsType, currentWorkOrder, defaultTripDate,
      maxStartDate, maxStartTime, maxEndDate, maxEndTime, overlappingTrips, tripToBeCloned, rowActionsOpen, currentCopyOptions,
    } = this.state;

    const {
      uiStore: { currentUser, currentUser: { accountId, accountInfo } },
      productStore,
      allowanceAutofillReminder,
      employerUpdateMode = false,
      // initialTabIndex,
      // employerContextTripFrom,
      // employerContextTripTo,
      employerContextTripId,
      highlightWorkHourId,
      openDialogCallback,
    } = this.props;

    // Move this outside of render? from and to are very likely static
    let activeWorkHours = workOrder.workHours.filter((item) => (
      // Filter by salary period + trip in employer context
      // ((employerUpdateMode && employerContextTripFrom && employerContextTripTo) ? item.date.isBetween(employerContextTripFrom,  employerContextTripTo, null, '[]') : item.date.isBetween(from, to, null, '[]'))
      item.date.isBetween(from, to, null, '[]')
      &&
      flatWorkDays.has(item.date.format('DD.MM.YY'))
      &&
      (store.showDraftsOnly ? item.isDraft() : true)
      &&
      ((employerUpdateMode && store.showEmployerUpdatedOnly) ? item.updatedByEmployer : true)
    ));

    // const employerUpdatedWorkHours = employerUpdateMode ? activeWorkHours.filter((hour) => hour.updatedByEmployer) : [];

    // const activeTab = initialTabIndex ? initialTabIndex : (activeTabById[workOrder.id] || 0);
    const activeTab = activeTabById[workOrder.id] || 0;
    // Move this out of render? Can be be recalculated at work hour change instead of at render
    const summary = getSummary(workOrder.workHourType, activeWorkHours, accountId);

    // This is also done in the constructor and the foundOnGoingTrip is placed into state.onGoingTrip
    // Probably a poor fix to the problem that constructor doesn't update the onGoingTrip when props change
    const foundOnGoingTrip = workOrderTrips.find(woTrip => !woTrip.endTrip || (woTrip.endTrip && woTrip.endTrip?.toTime == null || woTrip.endTrip?.toDate == null));
    const foundEndedTrips = workOrderTrips.filter(woTrip => woTrip.endTrip && woTrip.endTrip?.toTime != null);

    let tripRejected = false;
    this.props.workOrderTrips.find((trip) => {
      if ((trip.startTrip.status === 'rejected' || trip.endTrip.status === 'rejected') && trip.workOrders.map((wo) => wo.id).includes(workOrder.id)) {
        tripRejected = true;
        return trip;
      }
    });

    let workHourRejected = false;
    // Move this out of render? Could be done at work hour change?
    activeWorkHours.find((hour) => {
      const foundRejectedExpense = hour.taxExemptTripExpenses.find((expense) => expense.status === 'rejected')
      if (hour.status === 'rejected' || foundRejectedExpense) {
        workHourRejected = true;
        return hour;
      }
    });

    let tabZeroHeaders = ['työaika', 'normaali', '+50%', '+100%'];
    if (accountId === 59) {
      tabZeroHeaders = ['työaika', 'normaali'];
    }

    return (
      <>
        <CollapsibleList
          style={{
            backgroundColor: '#0a131e',
            marginTop: '1rem',
            maxWidth: '100vw',
          }}
          handle={(
            <SimpleListItem
              metaIcon="chevron_right"
              style={{
                backgroundColor: 'var(--mdc-theme-surface, #fff)',
                flexDirection: 'row-reverse',
                height: 'fit-content',
              }}
            >
              <WorkOrderItem {...{
                item: workOrder,
                key: `woi-${workOrder.id}-${workOrder.updatedAt.valueOf()}`,
                skipDescription: true,
                tripRejected: tripRejected,
                workHourRejected: workHourRejected,
                useGap: false,
                timelogViewTab: activeTab,
                view: 'timelogview',
              }}
              />
              {locked && <Icon icon="lock_outline" style={{ color: buttonColors.orange.color }} />}
            </SimpleListItem>
          )}
          open={employerUpdateMode || undefined}
        >
          {(!employerUpdateMode && disabled !== true && summary.hasUnacceptedHours) && this.renderAcceptance(workOrder)}
          {(employerUpdateMode && summary.hasUpdatedByEmployer) && this.renderAcceptanceEmployer()}
          <TabBar
            activeTabIndex={activeTab}
            onActivate={(evt) => {
              // Load the products when changing to products tab
              if (evt.detail.index === 5 && currentUser.accountInfo.productsEnabled && !employerUpdateMode) {
                productStore.findProducts();
                productStore.findUserProductCollections(currentUser);
              }
              this.setState({
                activeTabById: {
                  ...activeTabById,
                  [workOrder.id]: evt.detail.index,
                },
              });
            }}
          >
            <Tab icon="access_time" />
            <Tab icon="directions_car" />
            {/* <Tab className="pro-icon" icon={activeTab === 2 ? iconDailyAllowanceActive : iconDailyAllowance} > {tripRejected && <Icon style={{color: 'red',fontSize: '15px', top:'10px', left: '-10px', position: 'absolute'}} className="timelog-row-icon" icon="info" />}</Tab> */}
            <Tab className="pro-icon" icon={activeTab === 2 ? iconDailyAllowanceActive : iconDailyAllowance} />
            {/* Hide the tab number 4 from account 59 */}
            <Tab className="pro-icon" icon={activeTab === 3 ? iconExtrasActive : iconExtras} style={{ display: accountId === 59 && 'none' }} />
            <Tab icon="description" />
            {accountInfo.productsEnabled && !employerUpdateMode && <Tab icon="list_alt" />}
          </TabBar>

          <div>
            <div
              style={{ backgroundColor: 'var(--mdc-theme-surface, #fff)', width: '100%' }}
              theme="textPrimaryOnDark"
            >
              {activeTab === 4 && (
                <Attachments
                  disabled={disabled}
                  data={attachments}
                  inputRef={React.createRef()}
                  periodId={periodId}
                  // t={t}
                  // timelogStore={timelogStore}
                  userId={uiStore.currentUser.id}
                  workOrder={workOrder}
                />
              )}

              <div
                className="timelog-fake-table"
                style={{ paddingTop: '10px', paddingBottom: '10px' }}
              >
                <div
                  className={`${((activeTab === 1 && (accountId === 43 || accountId === 1 || accountId === 58)) || activeTab === 3) ? "mobile-fit-content" : ""}`}
                  style={{
                    display: 'flex', fontWeight: '600', borderBottom: '1px solid var(--mdc-theme-primary)',
                  }}
                >
                  {(activeTab !== 2 && activeTab !== 4 && activeTab !== 5) && this.getFirstColumn('yhteensä', {
                    borderBottom: 'none', lineHeight: '2rem', width: '17.5%', color: 'var(--mdc-theme-primary)',
                  }, activeTab)}

                  {activeTab === 0 && accountId !== 59 && [
                    this.getHeaderTitle('', 'total1-1', { width: '30%', color: 'var(--mdc-theme-primary)' }),
                    this.getHeaderTitle(summary.timeNormal, 'total1-2', defaultHeaderStyle),
                    this.getHeaderTitle(summary.time50, 'total1-3', defaultHeaderStyle),
                    this.getHeaderTitle(summary.time100, 'total1-4', defaultHeaderStyle),
                  ]}
                  {activeTab === 0 && accountId === 59 && [
                    this.getHeaderTitle('', 'total1-1', { width: '30%', color: 'var(--mdc-theme-primary)' }),
                    this.getHeaderTitle(summary.timeNormal, 'total1-2', defaultHeaderStyle),
                  ]}
                  {/* DEFAULT */}
                  {activeTab === 1 && accountId !== 1 && accountId !== 7 && accountId !== 10 && accountId !== 3 && accountId !== 41 && accountId !== 42 && accountId !== 43 && accountId !== 6 && accountId !== 49 && accountId !== 58 && accountId !== 59 && accountId !== 159 && [
                    this.getHeaderTitle(summary.travelTime, 'total2-7', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kmsService, 'total2-1', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kms, 'total2-4', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kmsPerson, 'total2-5', defaultHeaderStyle),
                  ]}
                  {activeTab === 1 && accountId === 1 && [
                    this.getHeaderTitle(summary.travelTime, 'total2-1', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.driveTime, 'total2-2', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.kms, 'total2-4', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.kmsPerson, 'total2-5', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.kmsService, 'total2-6', defaultHeaderStyle, activeTab),
                  ]}
                  {activeTab === 1 && (accountId === 7) && [
                    this.getHeaderTitle(summary.travelTime, 'total2-2', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kms, 'total2-4', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kmsService, 'total2-6', defaultHeaderStyle),
                  ]}
                  {activeTab === 1 && (accountId === 10 || accountId === 3 || accountId === 159) && [
                    this.getHeaderTitle(summary.travelTime, 'total2-2', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kms, 'total2-4', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kmsKit, 'total2-5', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kmsService, 'total2-6', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kmsPerson, 'total2-7', defaultHeaderStyle),
                  ]}
                  {activeTab === 1 && accountId === 41 && [
                    this.getHeaderTitle(summary.travelTime, 'total2-1', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kms, 'total2-4', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kmsPerson, 'total2-5', defaultHeaderStyle),
                    this.kmsTrailerOrServiceHeader(summary, '16.5%', 'var(--mdc-theme-primary)'),
                  ]}
                  {activeTab === 1 && accountId === 49 && [
                    this.getHeaderTitle(summary.travelTime, 'total2-1', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kms, 'total2-4', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kmsPerson, 'total2-5', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kmsTrailer, 'total2-6', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kmsService, 'total2-7', defaultHeaderStyle),
                  ]}
                  {activeTab === 1 && (accountId === 42 || accountId === 6) && [
                    this.getHeaderTitle(summary.travelTime, 'total2-1', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kms, 'total2-4', defaultHeaderStyle),
                    this.getHeaderTitle(summary.kmsPerson, 'total2-5', defaultHeaderStyle),
                    this.kmsTrailerOrServiceHeader(summary, '16.5%', 'var(--mdc-theme-primary)'),
                  ]}
                  {activeTab === 1 && accountId === 43 && [
                    this.getHeaderTitle(summary.travelTime, 'total2-1', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.driveTime, 'total2-2', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.kms, 'total2-4', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.kmsPerson, 'total2-5', defaultHeaderStyle, activeTab),
                    this.kmsTrailerOrServiceHeader(summary, '16.5%', 'var(--mdc-theme-primary)', activeTab),
                    this.getHeaderTitle(summary.kmsHeavyLoad, 'total2-7', defaultHeaderStyle, activeTab),
                  ]}
                  {activeTab === 1 && accountId === 58 && [
                    this.getHeaderTitle(summary.travelTime, 'total2-1', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.kmsService, 'total2-2', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.driveTime, 'total2-3', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.kms, 'total2-4', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.kmsPerson, 'total2-5', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.kmsHeavyLoad, 'total2-6', defaultHeaderStyle, activeTab),
                  ]}
                  {activeTab === 1 && accountId === 59 && [
                    this.getHeaderTitle(summary.kmsService, 'total2-2', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.kms, 'total2-4', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.travelTime, 'total2-3', defaultHeaderStyle, activeTab),
                  ]}
                  {activeTab === 2 && [
                    this.getHeaderTitle('', 'total5-1', { width: '82.5%' }),
                  ]}
                  {activeTab === 3 && accountId !== 7 && accountId !== 10 && accountId !== 43 && accountId !== 49 && accountId !== 58 && accountId !== 3 && accountId !== 159 && accountId !== 192 && accountId !== 93 && [
                    // this.getHeaderTitle(summary.sundayWork, 'total3-1', defaultHeaderStyle),
                    this.conditionOrSundayWorkHeader(summary, '16.5%', 'var(--mdc-theme-primary)', activeTab),
                    this.getHeaderTitle(summary.weeklyRest, 'total3-2', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.eveningShift, 'total3-3', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.nightShift, 'total3-4', defaultHeaderStyle, activeTab),
                    this.conditionOrEmergencyHeader(summary, '16.5%', 'var(--mdc-theme-primary)', activeTab),
                    // this.getHeaderTitle(summary.emergencyWork, 'total3-5'),
                    this.getHeaderTitle(summary.midweekHolidayCompensation, 'total3-6', defaultHeaderStyle, activeTab),
                  ]}
                  {activeTab === 3 && (accountId === 7 || accountId === 10) && [
                    this.getHeaderTitle(summary.sundayWork, 'total3-1', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.conditionCompensation, 'total3-2', defaultHeaderStyle, activeTab),
                    // this.conditionOrSundayWorkHeader(summary, '16.5%', 'var(--mdc-theme-primary)', activeTab),
                    this.getHeaderTitle(summary.weeklyRest, 'total3-3', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.eveningShift, 'total3-4', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.nightShift, 'total3-5', defaultHeaderStyle, activeTab),
                    // this.conditionOrEmergencyHeader(summary, '16.5%', 'var(--mdc-theme-primary)', activeTab),
                    this.getHeaderTitle(summary.emergencyWork, 'total3-6', defaultHeaderStyle, activeTab),
                    // this.getHeaderTitle(summary.emergencyWork, 'total3-5'),
                    this.getHeaderTitle(summary.miningCompensation, 'total3-7', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.midweekHolidayCompensation, 'total3-8', defaultHeaderStyle, activeTab),
                  ]}
                  {activeTab == 3 && accountId === 43 && [
                    this.conditionOrSundayWorkHeader(summary, '16.5%', 'var(--mdc-theme-primary)', activeTab),
                    this.getHeaderTitle(summary.weeklyRest, 'total3-2', defaultHeaderStyle, activeTab),
                    this.conditionOrEmergencyHeader(summary, '16.5%', 'var(--mdc-theme-primary)', activeTab),
                    this.getHeaderTitle(summary.midweekHolidayCompensation, 'total3-6', defaultHeaderStyle, activeTab),
                  ]}
                  {(activeTab === 3 && (accountId === 49 || accountId === 159 || accountId === 3 || accountId === 192 || accountId === 93)) && [
                    this.getHeaderTitle(summary.sundayWork, 'total3-1', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.conditionCompensation, 'total3-2', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.weeklyRest, 'total3-3', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.eveningShift, 'total3-4', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.nightShift, 'total3-6', defaultHeaderStyle, activeTab),
                    this.conditionOrEmergencyHeader(summary, '16.5%', 'var(--mdc-theme-primary)', activeTab),
                    this.getHeaderTitle(summary.midweekHolidayCompensation, 'total3-7', defaultHeaderStyle, activeTab),
                  ]}
                  {activeTab === 3 && accountId === 58 && [
                    this.getHeaderTitle(summary.sundayWork, 'total3-1', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.conditionCompensation, 'total3-2', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.conditionCompensationTwo, 'total3-3', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.conditionCompensationThree, 'total3-4', defaultHeaderStyle, activeTab),
                    // total3-5 key is used by conditionOrEmergencyHeader
                    this.getHeaderTitle(summary.conditionCompensationFour, 'total3-6', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.weeklyRest, 'total3-7', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.eveningShift, 'total3-8', defaultHeaderStyle, activeTab),
                    this.getHeaderTitle(summary.nightShift, 'total3-9', defaultHeaderStyle, activeTab),
                    this.conditionOrEmergencyHeader(summary, '16.5%', 'var(--mdc-theme-primary)', activeTab),
                    this.getHeaderTitle(summary.midweekHolidayCompensation, 'total3-10', defaultHeaderStyle, activeTab),
                  ]}
                  {activeTab === 4 && [
                    this.getHeaderTitle('', 'total4-1'),
                  ]}
                  {activeTab === 5 && [
                    this.getHeaderTitle('', 'total4-1'),
                  ]}
                </div>

                <div
                  className={`${((activeTab === 1 && (accountId === 43 || accountId === 1 || accountId === 58)) || activeTab === 3) ? "mobile-fit-content" : ""}`}
                  style={{
                    display: 'flex', marginTop: '5px', marginBottom: '5px', fontWeight: '600',
                  }}
                >
                  {this.getFirstColumn(' \npäivä', { borderTop: 0, width: '17.5%' }, activeTab)}
                  {activeTab === 0 && this.getHeader(tabZeroHeaders)}
                  {activeTab === 1 && this.getHeader(this.getTabOneHeaders(), activeTab)}
                  {/* {activeTab === 2 && getHeader(['päiväraha'])} */}
                  {activeTab === 2 && this.getHeader(this.getTabTwoHeaders())}
                  {activeTab === 3 && this.getHeader(this.getTabThreeHeaders(), activeTab)}
                  {activeTab === 4 && this.getHeader(['lisätieto'])}
                  {/* DEACTIVATED PRODUCTS */}
                  {/* TODO: Hardcoded to account ids 10 and 3 (test accounts) */}
                  {activeTab === 5 && accountInfo.productsEnabled && !employerUpdateMode && this.getHeader(['tuotteet'])}
                </div>

                <div
                  style={{
                    width: '100%',
                  }}
                >
                  {activeWorkHours.map((timelogentry, index) => {
                    // Removed the conditional part from the key, because it was causing the component to rerender when the key changed. If needed it was -${timelogentry.id || 'unsaved'}
                    const key = `tbrow-${workOrder.id}-${timelogentry.date.format('YYYY-MM-DD')}${timelogentry.id === this.state.copiedRowId ? `-${timelogentry.id}` : ''}`;
                    return (
                      <>
                        <TimelogRow
                          activeTab={activeTab}
                          toggleRouteDialog={this.toggleRouteDialog}
                          toggleTripDialog={this.toggleTripDialog}
                          openTripDisabledDialog={this.openTripDisabledDialog}
                          disabled={disabled}
                          item={timelogentry}
                          key={key}
                          employerAcceptance={workOrder.employerAcceptance}
                          onGoingTrip={foundOnGoingTrip}
                          endedTrips={foundEndedTrips}
                          workOrderName={workOrder.name}
                          workOrder={workOrder}
                          periodId={periodId}
                          allowanceAutofillReminder={allowanceAutofillReminder}
                          employerUpdateMode={employerUpdateMode}
                          relayUpdatedWorkHours={relayUpdatedWorkHours}
                          employerContextTripId={employerContextTripId}
                          highlight={highlightWorkHourId && highlightWorkHourId === timelogentry.id}
                          showRowActions={accountInfo.workHourCopyingEnabled && this.showRowActions}
                          closeRowActions={accountInfo.workHourCopyingEnabled && this.closeRowActions}
                          openDialogCallback={employerUpdateMode && openDialogCallback}
                        />
                        {!employerUpdateMode && accountInfo.workHourCopyingEnabled && activeTab === 0 && rowActionsOpen === key && (
                          <div style={{ fontSize: '14px', padding: '10px' }}>
                            {currentCopyOptions.length > 0 && this.renderCopyButtons()}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              {routeDialogOpen &&
                <TimelogRoute
                  routeDialogOpen={this.state != null ? routeDialogOpen : null}
                  toggleRouteDialog={this.state != null ? this.toggleRouteDialog : null}
                  // tripRoutes={tripRoutes}
                  kmsType={currentKmsType}
                  workOrder={currentWorkOrder}
                  workHour={routeItem}
                  overwriteTripRoutes={this.overwriteTripRoutes}
                  employerUpdateMode={employerUpdateMode}
                  relayUpdatedWorkHours={relayUpdatedWorkHours}
                />
              }
              {tripDialogOpen &&
                <TimelogTripDialog
                  tripDialogOpen={this.state != null ? tripDialogOpen : null}
                  toggleTripDialog={this.state != null ? this.toggleTripDialog : null}
                  workOrderTrip={tripItem}
                  workOrder={workOrder}
                  defaultTripDate={defaultTripDate}
                  maxStartDate={maxStartDate}
                  maxStartTime={maxStartTime}
                  maxEndDate={maxEndDate}
                  maxEndTime={maxEndTime}
                  overlappingTrips={overlappingTrips || []}
                  // We do not give the onGoingTrip to the dialog for overlap checking if it is the one being opened
                  onGoingTrip={tripItem?.id === foundOnGoingTrip?.id ? null : foundOnGoingTrip}
                  tripToBeCloned={tripToBeCloned}
                  allTrips={workOrderTrips}
                  employerContextTripId={employerContextTripId}
                  employerMode={employerUpdateMode}
                />
              }
              {this.renderTripDisabledDialog()}
              {this.renderCloneTripDialog()}
            </div>
          </div>
        </CollapsibleList>
      </>
    );
  }

  viewNextCopyOption() {
    const { currentCopyOptions, visibleCopyOptionIndex } = this.state;
    if (currentCopyOptions.length === (visibleCopyOptionIndex + 1)) {
      this.setState(() => ({
        visibleCopyOptionIndex: 0,
      }));
    } else {
      this.setState((prevState) => ({
        visibleCopyOptionIndex: prevState.visibleCopyOptionIndex + 1,
      }));
    }
  }

  renderCopyButtons() {
    const { currentCopyOptions, visibleCopyOptionIndex } = this.state;
    const currentCopyOption = currentCopyOptions[visibleCopyOptionIndex];

    return (
      <div style={{ textAlign: 'center' }}>
        {this.renderCopyButton(currentCopyOption.target, currentCopyOption.original, currentCopyOption.label)}

        {currentCopyOptions.length && (
          <IconButton
            aria-label="switch-copy-target"
            style={{
              display: currentCopyOptions.length > 1 ? 'initial' : 'none',
              padding: '6px',
              // marginLeft: '10px',
              // color: '#FFFFFF80',
              color: '#FFC900',
              border: '1px solid #FFC900',
              borderLeftWidth: 0,
              fontSize: '22px',
              width: '40px',
              height: '38.5px',
            }}
            onClick={() => this.viewNextCopyOption()}
          >
            <Icon icon="autorenew" role="button" style={{ fontSize: '22px' }} />
          </IconButton>
        )}
      </div>
    )
  }

  renderCopyButton(target, original, label) {
    return (
      // Doesn't work because it assumes button has two labels, so animation shows two labels at once, first of which is invisible before disappearing completely
      // <Button
      //   onClick={() => this.copyWorkHour(target, original)}
      //   className="employee-accept-button-outlined"
      //   style={{ margin: '0 10px', width: '260px' }}
      // >
      //   {/* <animated.div style={styles}>
      //     {label}
      //   </animated.div> */}
      //   {/* <animated.span style={springProps}>{label}</animated.span> */}
      //   <Transition
      //     items={[label]}
      //     keys={(item) => item}
      //     from={{ opacity: 0, width: '100%', whiteSpace: 'nowrap' }}
      //     enter={{ opacity: 1, width: '100%', whiteSpace: 'nowrap' }}
      //     leave={{ opacity: 0, width: '100%', whiteSpace: 'nowrap' }}
      //     // delay={100}
      //     config={config.default}
      //   >
      //     {(styles, item) => (
      //       <animated.div style={styles}>
      //         {label}
      //       </animated.div>
      //     )}
      //   </Transition>
      // </Button>

      <Button
        onClick={() => this.copyWorkHour(target, original)}
        className="employee-accept-button-outlined"
        style={{ width: '290px' }}
      >
        <Icon icon="flip_to_front" style={{ marginRight: '5px', rotate: '90deg' }} />
        <span>
          {label}
        </span>
      </Button>
    );
  }

  showRowActions = (workHour, copyOptions) => {
    const { workOrder } = this.props;
    const key = `tbrow-${workOrder.id}-${workHour.date.format('YYYY-MM-DD')}`;

    this.setState((prevState) => ({
      // rowActionsOpen: { ...prevState.rowActionsOpen, [key]: true },
      rowActionsOpen: key,
      currentCopyOptions: copyOptions,
      visibleCopyOptionIndex: 0,
    }));
  }

  closeRowActions = () => {
    this.setState((prevState) => ({
      // rowActionsOpen: { ...prevState.rowActionsOpen, [key]: false },
      rowActionsOpen: null,
      currentCopyOptions: [],
      visibleCopyOptionIndex: 0,
    }));
  }

  copyWorkHour(target, original) {
    const { timelogStore } = this.props;

    const workHourCopy = original.copy();
    workHourCopy.date = target.date;

    target.updateProperties(workHourCopy);
    timelogStore.updateTimelogEntry(target, null).then(() => {
      // ChatGPT 4o 13.6.2024
      // Initialize a counter for the values
      // let valueCount = 0;
      // const excludeKeys = ['id', 'createdAt', 'updatedAt', 'employerComment', 'employerUpdateComment', 'latestEmployeeVersion', 'status', 'timeTotal', 'userId', 'workOrderId', 'workOrderTripEmployerComment', 'workTask', 'date'];

      // // Iterate over each key in the object
      // for (let key in target) {
      //   // Check if the key is not in the excludeKeys array
      //   if (target.hasOwnProperty(key) && target[key] && !excludeKeys.includes(key)) {
      //     // This kind of array object handling is stupid
      //     // How to fix:
      //     // 1. Add "hasTaxExemptTripExpenses" methods to TimelogEntry.js
      //     // 2. Make it so that false objects aren't saved here (null value tax exempt trip expenses, id-less trip routes, etc.)
      //     // 3. Something else?
      //     if (key === 'workTaskEntries' && target[key]?.length > 0) {
      //       console.log('Found value in key:', key);
      //       valueCount++;
      //     } else if (key === 'taxExemptTripExpenses' && target[key]?.length > 0 && target[key][0]?.value) {
      //       console.log('Found value in key:', key);
      //       console.log(target[key]);
      //       valueCount++;
      //     } else if (key === 'tripRoutes' && target[key]?.length > 0 && target[key][0]?.id) {
      //       console.log('Found value in key:', key);
      //       console.log(target[key]);
      //       valueCount++;
      //     } else if (key !== 'workTaskEntries' && key !== 'taxExemptTripExpenses' && key !== 'tripRoutes') {
      //       console.log('Found value in key:', key);
      //       console.log(target[key]);
      //       valueCount++;
      //     }
      //   }
      // }

      // this.openGlobalSnackbar(`${valueCount} saraketta kopioitu päivälle ${target.date.format('dd DD.MM.')}`);
      this.setState(() => ({
        copiedRowId: target.id,
      }));
      this.openGlobalSnackbar(`Rivi kopioitu päivälle ${target.date.format('dd DD.MM.')}`);
    }).catch((err) => {
      console.error(err);
      this.openGlobalSnackbar('Kopiointi epäonnistui');
    });
  }

  openGlobalSnackbar = (message) => {
    const { globalSnackbarStore } = this.props;
    globalSnackbarStore.openSnackbar(message);
  }
}

export default TimelogView;

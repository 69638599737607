/* eslint-disable no-else-return */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { Select } from '@rmwc/select';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Link,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LoadingSpinner from '../shared/LoadingSpinner';
import EmployeesTable from './EmployeesTable';
import EmployeeDialog from './EmployeeDialog';
import User from '../models/User';

@inject('uiStore', 'loginStore', 't', 'employerUserStore')
@observer
class Employees extends Component {
  constructor(props) {
    super(props);

    const { employerUserStore } = this.props;

    this.state = {
      showInvitationDialog: false,
      invitationEmail: '',
      invitationSalaryPeriodCategory: null,
      employeeEditDialogOpen: false,
      existingUser: null,
      sendingEmail: false,
    };

    employerUserStore.getUsers();
  }

  // componentDidMount() {
  //   const { employerUserStore: { users } } = this.props;

  //   users.then((data) => {
  //     console.log('Did mount data: ', data);
  //     this.setState({
  //       data: data,
  //     }, () => {
  //       return true;
  //     });
  //   });
  // }

  closeEmployeeEditDialog = () => {
    this.setState({
      employeeEditDialogOpen: false,
      existingUser: null,
    });
  }

  openEmployeeEditDialog() {
    this.setState({
      employeeEditDialogOpen: true,
    });
  }

  sendInvitation() {
    const { employerUserStore } = this.props;
    const { invitationEmail, invitationSalaryPeriodCategory } = this.state;

    // Create a promise using 'fromPromise' to handle the asynchronous invitation process
    const invitationPromise = fromPromise(
      new Promise((resolve, reject) => employerUserStore.sendInvite(invitationEmail, invitationSalaryPeriodCategory, resolve, reject)),
    );

    this.setState({ sendingEmail: true });

    // Use 'when' to observe the promise and perform actions based on its state
    when(
      () => invitationPromise.state !== 'pending',
      () => {
        invitationPromise.case({
          pending: () => { },
          rejected: (err) => {
            if (err.response?.body?.message === 'Email taken') {
              // Handle the "Email taken" error case
              const existingUser = employerUserStore.getUserByEmail(invitationEmail);
              this.setState({
                existingUser,
                emailHelperText: 'Sähköposti on jo käytössä',
                emailError: true,
                sendingEmail: false,
              });
            } else {
              // console.log(err.message);
              // Handle other errors
              // TO DO There is a problem here. When we send a new invitation to a user that has not yet registered we get Unprocessable Entity error because we already have an user account but we still need to send the new invitation.
              // This and all other errors are currently ignored and we just close the dialog because there has not been a good solution that takes all exceptions in to account.
              this.setState({
                // emailHelperText: 'Kutsun lähettämisessä tapahtui virhe. Yritä hetken kuluttua uudelleen',
                emailHelperText: '',
                emailError: false,
                showInvitationDialog: false,
                sendingEmail: false,
              });
            }
          },
          fulfilled: () => {
            // Handle the fulfillment case (invitation sent successfully)
            this.setState({ showInvitationDialog: false, sendingEmail: false });
          },
        });
      },
    );
  }

  addUserManually() {
    const { uiStore: { currentUser: { accountInfo: { salaryPeriodCategories } } } } = this.props;
    const { invitationSalaryPeriodCategory } = this.state;
    const defaultSalaryperiodCategory = salaryPeriodCategories.find((category) => category.is_default);
    const newUserSalaryPeriodCategory = invitationSalaryPeriodCategory ? salaryPeriodCategories.find((category) => (Number(invitationSalaryPeriodCategory) === Number(category.id))) : null;

    this.setState((prevState) => ({
      showInvitationDialog: false,
      invitationEmail: '',
      invitationSalaryPeriodCategory: defaultSalaryperiodCategory?.id,
      emailError: null,
      emailHelperText: null,
      employeeEditDialogOpen: true,
      // new User({ email: prevState.invitationEmail, salaryPeriodCategory: newUserSalaryPeriodCategory, details: { clothing: { hasHelmet: false } }, ...employee }),
      existingUser: new User({
        email: prevState.invitationEmail,
        salaryPeriodCategory: newUserSalaryPeriodCategory,
        details: { clothing: { hasHelmet: false } },
      }),
    }));
  }

  closeInvitationDialog(resetExistingUser = true) {
    const { uiStore: { currentUser: { accountInfo: { salaryPeriodCategories } } } } = this.props;

    const defaultSalaryperiodCategory = salaryPeriodCategories.find((category) => category.is_default);

    this.setState((prevState) => ({
      showInvitationDialog: false,
      invitationEmail: '',
      invitationSalaryPeriodCategory: defaultSalaryperiodCategory?.id,
      emailError: null,
      emailHelperText: null,
      sendingEmail: false,
      existingUser: resetExistingUser ? null : prevState.existingUser,
    }));
  }

  // This is copied from Validity since some changes were needed for rendering
  renderCardValidity(validThrough) {
    if (!this.validThrough) {
      return null;
    }

    const currentDateMoment = moment().local(); // eslint-disable-line
    // eslint-disable-next-line
    const validThroughMoment = Validity.convertDate(validThrough).dateObject; // moment(secondDate, 'MM/YYYY');
    // Add 1 to the months because for some reason they're zero indexed (e.g. January is 0 and December is 11)
    // const currentMonth = currentDate.month() + 1;
    // const secondDateMonth = secondDate.month() + 1;

    // Object not returned (invalid date given to conversion), which means the date cannot be calculated
    if (!validThroughMoment) {
      return null;
    }

    if (currentDateMoment > validThroughMoment.endOf('month')) {
      // Expired (last day of the month)
      return (
        <div style={{ color: 'red', fontWeight: 'bold' }}>
          {validThrough}
        </div>
      );
    } else if (currentDateMoment.add(1, 'M') > validThroughMoment.endOf('month')) {
      // Expiring within a month
      return (
        <div style={{ color: 'var(--mdc-theme-primary)', fontWeight: 'bold' }}>
          {validThrough}
        </div>
      );
    } else {
      return (
        <div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
          {validThrough}
        </div>
      );
    }
  }

  renderTable(users) {
    const { uiStore: { currentUser: { accountId } } } = this.props;
    // Default preset
    const validitiesPresetDefault = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.machineryPermit.imageURL',
    ];

    // TODO: Hardcoded to account IDS 7, 10 and 3
    const validitiesPreset1 = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.roadSafetyCard.number',
      'details.cards.roadSafetyCard.validThrough',
      'details.cards.roadSafetyCard.imageURL',
      'details.cards.ssgCard.number',
      'details.cards.ssgCard.validThrough',
      'details.cards.ssgCard.imageURL',
      'details.cards.id06Card.number',
      'details.cards.id06Card.validThrough',
      'details.cards.id06Card.imageURL',
      'details.cards.tankSafetyCard.number',
      'details.cards.tankSafetyCard.validThrough',
      'details.cards.tankSafetyCard.imageURL',
    ];

    // TODO: Hardcoded to account ID 42
    const validitiesPreset2 = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.ssgCard.number',
      'details.cards.ssgCard.validThrough',
      'details.cards.ssgCard.imageURL',
      'details.cards.id06Card.number',
      'details.cards.id06Card.validThrough',
      'details.cards.id06Card.imageURL',
      'edit',
    ];

    const validitiesPreset3 = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.machineryPermit.imageURL',
      'details.cards.ssgCard.number',
      'details.cards.ssgCard.validThrough',
      'details.cards.ssgCard.imageURL',
    ];

    const validitiesPreset49 = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.machineryPermit.imageURL',
      'details.cards.ssgCard.number',
      'details.cards.ssgCard.validThrough',
      'details.cards.ssgCard.imageURL',
    ];

    const validitiesPreset58 = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.machineryPermit.imageURL',
      'details.cards.ssgCard.number',
      'details.cards.ssgCard.validThrough',
      'details.cards.ssgCard.imageURL',
    ];

    // Card validity preset for account 159 (Vihucon)
    const validitiesPreset159 = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.machineryPermit.imageURL',
      'details.cards.ssgCard.number',
      'details.cards.ssgCard.validThrough',
      'details.cards.ssgCard.imageURL',
      'details.card.tankSafetyCard.number',
      'details.card.tankSafetyCard.validThrough',
      'details.card.tankSafetyCard.imageURL',
    ];

    let validitiesPreset;
    switch (accountId) {
      case 7:
      case 10:
      case 3:
        validitiesPreset = validitiesPreset1;
        break;
      case 42:
        validitiesPreset = validitiesPreset2;
        break;
      case 1:
        validitiesPreset = validitiesPreset3;
        break;
      case 49:
        validitiesPreset = validitiesPreset49;
        break;
      case 58:
        validitiesPreset = validitiesPreset58;
        break;
      case 159:
        validitiesPreset = validitiesPreset159;
        break;
      default:
        validitiesPreset = validitiesPresetDefault;
    }

    return (
      <EmployeesTable
        data={users}
        hasMargins
        defaultFilterLists={{ active: ['Kyllä'], hasCompletedRegistration: ['Kyllä'] }}
        presets={{
          basic: [
            'lastName',
            'firstName',
            'email',
            'phoneNumber',
            'active',
            'edit',
          ],
          validities: validitiesPreset,
          salary: [
            'lastName',
            'firstName',
            'socialSecurityNumber',
            'city',
            'email',
            'phoneNumber',
            'taxIncomeCardUrl',
            'taxPercent',
            'bankNumber',
            'externalId',
            'externalGroup',
            'subcontractor',
            'salaryPeriodCategory.name',
            'edit',
          ],
          personal: [
            'lastName',
            'firstName',
            'personalPictureUrl',
            'taxNumber',
            'birthDate',
            'edit',
          ],
          clothing: [
            'lastName',
            'firstName',
            'details.clothing.size',
            'details.clothing.number',
            'details.clothing.shoeSize',
            'details.clothing.hasHelmet',
            'details.clothing.helmetSize',
            'helmetImageUrl',
            'edit',
          ],
          // The key 'all' is used to ignore the columns array in the EmployeesTable.toggleColumnVisibilityPreset()
          // We keep do this instead of naming every column in the 'all' preset to keep things more dynamic, if more columns are added
          all: [],
        }}
      />
    );
  }

  render() {
    const { t, employerUserStore: { users }, uiStore: { currentUser: { accountInfo: { salaryPeriodCategoriesEnabled, salaryPeriodCategories } } } } = this.props;
    const {
      showInvitationDialog,
      invitationEmail,
      invitationSalaryPeriodCategory,
      emailError,
      emailHelperText,
      existingUser,
      employeeEditDialogOpen,
      sendingEmail,
    } = this.state;

    const defaultSalaryperiodCategory = salaryPeriodCategories.find((category) => category.is_default);

    return (
      <>
        <Grid
          style={{ width: '100%', height: '100%', padding: '0 0 16px 0' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              desktop={6}
              tablet={4}
              mobile={4}
            >
              <div className="view-heading">
                <div style={{ flexDirection: 'column', justifyContent: 'center' }}>Työntekijät</div>
              </div>
            </GridCell>

            <GridCell
              desktop={6}
              tablet={4}
              mobile={4}
            >
              <div style={{ textAlign: 'end', marginTop: '10px' }}>
                {users && (
                  <Button
                    onClick={() => this.setState({ showInvitationDialog: true, invitationEmail: '', invitationSalaryPeriodCategory: defaultSalaryperiodCategory?.id })}
                    startIcon={<AddIcon />}
                    className="employer-accept-button"
                    style={{
                      borderRadius: '0px',
                      width: '160px',
                      marginRight: '10px',
                      margin: '10px 10px 10px 10px',
                    }}
                  >
                    {t('user.new')}
                  </Button>
                )}

                {/* {users && (
                  <Button
                    onClick={() => this.setState({
                      employeeEditDialogOpen: true,
                      existingUser: new User(),
                    })}
                    // icon="add"
                    className="employer-accept-button"
                    style={{
                      borderRadius: '0px',
                      width: '150px',
                      margin: '10px 10px 10px 10px',
                    }}
                  >
                    Luo uusi
                  </Button>
                )} */}
              </div>
            </GridCell>

            <GridCell
              span={12}
            >

              {users && this.renderTable(users)}

              {!users && (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <LoadingSpinner color="black" />
                </div>
              )}

              <Dialog
                open={showInvitationDialog}
                onClose={() => this.closeInvitationDialog()}
                fullWidth
                maxWidth="xs"
              >
                <DialogTitle style={{ paddingBottom: 0 }}>
                  <div>{t('user.new_dialog_title')}</div>
                  <div className="billing-report-link" style={{ textAlign: 'end', lineHeight: 0, margin: 0 }}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      component="button"
                      onClick={() => this.addUserManually()}
                    >
                      Lisää käsin
                    </Link>
                  </div>
                </DialogTitle>

                <DialogContent>
                  <TextField {...{
                    style: {
                      backgroundColor: '#FFFFFF',
                      width: '100%',
                      margin: '0.3em 0',
                      whiteSpace: 'pre',
                    },
                    className: 'mui-label-white-background',
                    label: 'Sähköposti',
                    required: true,
                    name: 'email',
                    type: 'email',
                    error: emailError,
                    helperText: emailHelperText,
                    onChange: (event) => {
                      const { value } = event.target;
                      // delete errors[attr]
                      this.setState({
                        invitationEmail: value,
                        // inputChanged: true,
                        // emailError: null,
                        // emailHelperText: '',
                      });
                      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if (!regex.test(value)) {
                        this.setState({
                          emailError: true,
                          emailHelperText: 'Tarkista sähköposti',
                        });
                      } else {
                        this.setState({
                          emailError: false,
                          emailHelperText: null,
                        });
                      }
                    },
                    // onKeyPress: handleEnter,
                    value: invitationEmail || '',
                  }}
                  />

                  {salaryPeriodCategoriesEnabled && (
                    <Select
                      className="rmwc-select"
                      style={{ top: 'auto !important' }}
                      {...{
                        // className: 'ml-1 pk-select profile-input-95-view-width no-border-bottom label-style', // mdc-theme--text-primary-on-dark
                        label: t('profile.labels.salaryPeriodCategory'),
                        onChange: (event) => {
                          const { value } = event.target;
                          // delete errors[attr]
                          this.setState({
                            invitationSalaryPeriodCategory: value,
                          });
                        },
                        rootProps: { ripple: false },
                        value: invitationSalaryPeriodCategory,
                      }}
                    >
                      {salaryPeriodCategories?.map((category) => (
                        <option value={category.id} label={category.name} />
                      ))}
                    </Select>

                  // Material-ui select, the options don't stack vertically
                  // <FormControl fullWidth>
                  //   <InputLabel id="salary-period-category-label">
                  //     {t('profile.labels.salaryPeriodCategory')}
                  //   </InputLabel>

                  //   <Select
                  //     labelId="salary-period-category-label"
                  //     id="salary-period-category"
                  //     value={invitationSalaryPeriodCategory}
                  //     label="Age"
                  //     // onChange={handleChange}
                  //     onChange={(event) => {
                  //       const { value } = event.target;
                  //       // delete errors[attr]
                  //       this.setState({
                  //         invitationSalaryPeriodCategory: value,
                  //       });
                  //     }}
                  //   >
                  //     {salaryPeriodCategories?.map((category) => (
                  //       <MenuItem value={category.id}>
                  //         {category.name}
                  //       </MenuItem>
                  //     ))}
                  //   </Select>
                  // </FormControl>
                  )}
                </DialogContent>
                <DialogActions style={{
                  justifyContent:
                    (emailError === false || emailHelperText === 'Sähköposti on jo käytössä') && invitationEmail ? 'space-between' : 'flex-end',
                }}
                >
                  {emailError === false && invitationEmail && (
                    <Button
                      disabled={sendingEmail}
                      onClick={() => this.sendInvitation()}
                      className="employer-accept-button"
                      // Force the height to match the close button in order to prevent LoadingSpinner resizing it
                      style={{ height: '38.5px' }}
                    >
                      {sendingEmail ? <LoadingSpinner color="black" size="mini" /> : 'Lähetä'}
                    </Button>
                  )}
                  {emailHelperText === 'Sähköposti on jo käytössä' && invitationEmail && (
                    <Button
                      className="employer-accept-button"
                      style={{
                        whiteSpace: 'pre',
                      }}
                      onClick={() => {
                        this.openEmployeeEditDialog(existingUser);
                        this.closeInvitationDialog(false);
                      }}
                    >
                      Näytä profiili
                    </Button>
                  )}
                  <Button
                    onClick={() => this.closeInvitationDialog()}
                    className="employer-reject-button"
                  >
                    Sulje
                  </Button>
                </DialogActions>
              </Dialog>

              <EmployeeDialog
                open={employeeEditDialogOpen}
                handleClose={this.closeEmployeeEditDialog}
                employee={existingUser}
              />

            </GridCell>
          </GridInner>
        </Grid>
      </>
    );
  }
}

export default Employees;
